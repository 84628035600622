import React, { Component } from 'react';
import { Button, Spinner } from '../../../General';
import { graphQLFetch, sendAnalytics } from '../../../../helpers';
import './Widget.css';
import queries from './queries';
import imgRaf1 from '../../../../images/raf_coffee_home.jpg';
import imgZonen1 from '../../../../images/zonen09_1.jpg';

export default class Widget extends Component {
  constructor(props) {
    super(props);

    this.state = { company: null };
  }

  componentDidMount() {
    const { match } = this.props;
    const id = match.params.id;

    sendAnalytics(this.props);

    this.setState({ isLoading: true });
    graphQLFetch(queries.getCompanyQuery(id)).then(res => {
      this.setState({ company: res.data.company, isLoading: false });
    });
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    // if (this.state.shouldRedirect)
    //   return <Redirect to={`/bedrijf/${paramName}`} />;

    const company = this.state.company;

    if (this.state.isLoading)
      return (
        <div className="centered-container">
          <Spinner />
        </div>
      );

    if (!company) {
      return (
        <div className="centered-container">
          <p>Er is iets misgegaan. Neem contact met ons op.</p>
        </div>
      );
    }

    let cardImg = null;
    if (company.challenge.id === 1) cardImg = imgRaf1;
    else if (company.challenge.id === 2) cardImg = imgZonen1;

    return (
      <div className="Widget_">
        <p className="Widget_head">
          <a
            href="http://mijnidee.be"
            target="_blank"
            rel="noopener noreferrer"
          >
            mijnidee
          </a>{' '}
          / {company.name}
        </p>
        <h3>
          <a
            href={`http://mijnidee.be/challenge/${company.challenge.id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {company.challenge.title}
          </a>
        </h3>
        <img src={cardImg} alt="" />
        <p className="Widget_description">
          Doe mee aan deze challenge en help {company.name}!
        </p>
        <Button
          link={`http://mijnidee.be/challenge/${company.challenge.id}`}
          target="blank"
        >
          Bekijk challenge
        </Button>
      </div>
    );
  }
}

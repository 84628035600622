const getCompanyQuery = id => {
  return {
    query: `
      {
        company(id: ${id}) {
          id
          name
          region
          challenge {
            id
            title
          }
        }
      }
    `
  };
};

export default { getCompanyQuery };

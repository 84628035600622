import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';

import reactGA from 'react-ga';

import './App.css';
import { Navigator, ScrollToTop } from './components/Screens/';
import config from './config';

const client = new ApolloClient({
  uri: config.graphQL
});

class App extends Component {
  componentDidMount() {
    reactGA.initialize(config.ga.trackingId);
  }

  render() {
    return (
      <ApolloProvider client={client}>
        <BrowserRouter>
          <ScrollToTop>
            <Navigator />
          </ScrollToTop>
        </BrowserRouter>
      </ApolloProvider>
    );
  }
}

export default App;

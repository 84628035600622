import React, { Component } from 'react';
import type from 'prop-types';
import { Link } from '../../General';
import './Header.css';
import headerImg from '../../../images/header.png';
import logo from '../../../images/logo.svg';

export default class Header extends Component {
  render() {
    return (
      <div className="Header_container">
        <div className="container">
          <Link to="/">
            <img src={logo} className="Header_logo" alt="" />
          </Link>
          <img src={headerImg} className="Header_image" alt="" />
          <p className="Header_title-huge">
            Waarom
            <br />
            zou jij je laten
            <br />
            inspireren door je <br />
            klanten?
          </p>
          {/* <p className="Header_title-big">
            Beantwoord de uitdagingen met je ideeën en help
            <br />
            lokale bedrijven te innoveren.
          </p> */}

          {/* <a
            className="Header_scroll-arrow"
            onClick={this.props.onScrollArrowClick}
          >
            <i className="icon ion-ios-arrow-down" />
          </a> */}
        </div>
      </div>
    );
  }
}

Header.propTypes = {
  onScrollArrowClick: type.func
};

const validateTokenQuery = token => {
  return {
    query: `
      {
        validateToken(token: "${token}")
      }
    `
  };
};

export default { validateTokenQuery };

import React, { Component } from 'react';
import { Footer } from '../../General';
import { Link } from 'react-router-dom';
import { sendAnalytics } from '../../../helpers';
import './PrivacyScreen.css';

export default class CookieScreen extends Component {
  componentDidMount() {
    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    return (
      <div>
        <div className="PrivacyScreen_container">
          <p className="PrivacyScreen_back">
            <Link to="/">
              <i className="icon ion-ios-arrow-back" /> Terug naar website
            </Link>
          </p>
          <h1>COOKIEVERKLARING</h1>
          <h2>Wat zijn cookies?</h2>
          <p>
            Cookies zijn kleine data- of tekstbestandjes die bij het bezoeken
            van websites op je computer of mobiel apparaat kunnen worden
            bewaard. Ze bevatten informatie die gelinkt is aan een webbrowser en
            specifieke website. Als je naar een bepaalde website terugkeert, dan
            kan die pagina de bezoeker door middel van de cookie herkennen en de
            historiek verder opbouwen. Cookies worden meestal gebruikt om
            websites optimaal te laten functioneren, om handelingen van
            bezoekers van de website bij te houden, om inloggegevens te bewaren.
            Cookies kunnen geplaatst worden door de server van de website of
            applicatie die je bezoekt, maar ook door servers van derde partijen
            die al dan niet met deze website samenwerken.{' '}
          </p>

          <h2>Welke cookies worden er op onze website gebruikt?</h2>
          <h3>Functioneel of technisch noodzakelijke cookies</h3>
          <p>
            Functionele cookies zijn cookies die noodzakelijk zijn voor het goed
            functioneren van onze website en deze kan je dus niet weigeren als
            je onze website wil bezoeken. Deze cookies worden gebruikt door de
            beheerder van de bezochte site zelf. Zo kunnen wij bijvoorbeeld jouw
            taalinstellingen en formuliervoorkeuren bijhouden.
          </p>

          <h3>Analytische cookies</h3>
          <p>
            Om een betere gebruikservaring van de website te kunnen bieden
            wanneer je onze website bezoekt, maken wij ook gebruik van cookies
            voor analytische doeleinden van ons websiteverkeer. Op die manier
            kunnen wij onze website optimaliseren en je gebruikersvoorkeuren
            onderscheiden van andere bezoekers op de website.
          </p>
          <p>
            De cookies die we gebruiken voor de analytische doeleinden zijn
            cookies van een derde partij, namelijk Google Analytics. Op die
            manier kunnen wij een duidelijk beeld krijgen van bezoekersstromen,
            paginaweergaves, etc. Dit wil wel zeggen dat de informatie die wordt
            verzameld terechtkomt op servers die niet onder ons beheer vallen.
            Voor meer informatie over de cookies die Google Analytics plaatst en
            de data die zij hierbij verzamelt, verwijzen wij daarom naar de
            verklaring van Google Analytics daarover op haar website. Let er op
            dat deze verklaring regelmatig kan wijzigen en dat wij noch invloed
            uitoefenen op de inhoud van deze verklaringen, noch op de inhoud van
            de cookies van deze derde partij.
          </p>

          <h3>Social media cookies</h3>
          <p>
            Wanneer je op onze website een social media button aanklikt van
            Facebook, Instagram, Youtube, Twitter of Snapchat, wordt een social
            media cookie geplaatst. Deze social media cookie is afkomstig van
            een derde en maakt de functionaliteiten van de social mediawebsite
            in kwestie mogelijk. Voor de cookies die de social media partijen
            plaatsen en de data die zij hiermee verzamelen, verwijzen we naar de
            verklaringen die deze partijen op hun eigen websites daarover geven:
            Facebook, Instagram, Youtube, Twitter, Snapchat. Let er op dat deze
            verklaringen regelmatig kunnen wijzigen en dat wij noch invloed
            uitoefenen op de inhoud van deze verklaringen, noch op de inhoud van
            de cookies van deze derde partijen.{' '}
          </p>

          <h3>Advertentiecookies</h3>
          <p>
            Derde partijen, zoals Facebook, kunnen cookies plaatsen op de
            website van Odisee zodat er gebruik gemaakt kan worden van hun
            advertentiediensten. Op basis hiervan kunnen wij je aangepaste
            advertenties tonen op deze platformen, die afgestemd zijn op je
            leeftijd, regio, interesse,… Hiervoor verwijzen wij naar het
            privacybeleid van deze partijen.{' '}
          </p>

          <h2>Hoe kan je het gebruik van cookies beheren?</h2>
          <p>
            De meeste browsers aanvaarden automatisch cookies. Je kan je browser
            zo instellen dat je gewaarschuwd wordt vooraleer een cookie wordt
            geplaatst of zodat cookies geweigerd worden. Je kunt ook op elk
            moment de al geïnstalleerde cookies van je computer of mobiel
            apparaat verwijderen. Het uitschakelen van cookies kan betekenen dat
            je bepaalde functies van de website niet meer zal kunnen gebruiken.{' '}
          </p>

          <p>
            Op de volgende website vind je voor de meest gebruikte browsers de
            werkwijze om cookies te weigeren:{' '}
            <a
              href="http://www.aboutcookies.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://www.aboutcookies.org/
            </a>. Voor meer informatie per type brower kan je ook de volgende
            links raadplegen:{' '}
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
              target="_blank"
              rel="noopener noreferrer"
            >
              Internet Explorer
            </a>,{' '}
            <a
              href="http://support.mozilla.org/nl/kb/cookies-informatie-websites-op-uw-computer-opslaan"
              target="_blank"
              rel="noopener noreferrer"
            >
              Mozilla Firefox
            </a>,{' '}
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
              target="_blank"
              rel="noopener noreferrer"
            >
              Chrome
            </a>,{' '}
            <a
              href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies#ie=ie-11"
              target="_blank"
              rel="noopener noreferrer"
            >
              Safari
            </a>.
          </p>
          <p>
            Als je verschillende toestellen gebruikt om deze website te
            bezoeken, dan moet je je cookievoorkeur instellen op de browser van
            élk toestel.
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Footer } from '../../General';
import { sendAnalytics } from '../../../helpers';
import './PrivacyScreen.css';

export default class PrivacyScreen extends Component {
  componentDidMount() {
    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    return (
      <div>
        <div className="PrivacyScreen_container">
          <p className="PrivacyScreen_back">
            <Link to="/">
              <i className="icon ion-ios-arrow-back" /> Terug naar website
            </Link>
          </p>
          <h1>PRIVACYVERKLARING</h1>
          <p>
            Deze ‘Privacyverklaring’ regelt hoe jouw persoonsgegevens worden
            verwerkt door Odisee vzw, met maatschappelijke zetel te 1000
            Brussel, Warmoesberg 26, ingeschreven in het rechtspersonenregister
            onder ondernemingsnummer 0408.429.584 (Hierna: ‘Odisee’). Odisee is
            de
            <strong>verantwoordelijke voor de verwerking</strong> van
            persoonsgegevens die via haar website verkregen worden.
          </p>
          <p>
            {' '}
            Deze Privacyverklaring beschrijft welke persoonsgegevens we van jou
            verzamelen wanneer je onze website bezoekt of wanneer je gebruikt
            maakt van de diensten die Odisee via de website ‘mijnidee.be’
            aanbiedt, waarom we dat doen, hoelang wij ze bewaren, met wie wij ze
            delen, wat je rechten zijn en hoe je deze kan uitoefenen.
          </p>
          <p>
            {' '}
            In deze Privacyverklaring willen wij jou bovendien ook informeren
            over ons <Link to="/cookies">cookiebeleid</Link>. Wanneer in deze
            verklaring gerefereerd wordt naar ‘onze’ of ‘deze’ ‘website’, wordt
            de specifieke website bedoeld die start met https://mijnideebe.
          </p>

          <h2>Welke persoonsgegevens verwerken we? </h2>
          <p>
            Odisee verwerkt de persoonsgegevens die je zelf aan ons verstrekt,
            bijvoorbeeld wanneer je via onze website deelneemt aan een
            challenge.
          </p>
          <p>
            Meer bepaald kan je via deze formulieren de volgende
            persoonsgegevens aan ons verstrekken: e-mailadres.
          </p>
          <p>
            Sommige gegevens worden ook automatisch verzameld wanneer je onze
            website bezoekt, doordat wij gebruikmaken van cookies (kleine
            tekstbestandjes die worden opgeslagen in de browser van een
            gebruiker).
          </p>
          <p>
            Meer bepaald kunnen daarbij volgende persoonsgegevens worden
            verwerkt: je IP-adres, je browsertype, de door jou ingestelde taal,
            wanneer en hoe lang je onze website bezoekt,…
          </p>
          <p>
            Meer informatie over hoe we gebruikmaken van cookies, kan je vinden
            in onze <Link to="/cookies">Cookieverklaring</Link>.
          </p>

          <h2>Waarvoor verwerken wij jouw persoonsgegevens?</h2>

          <p>
            De persoonsgegevens die je zelf aan ons verstrekt, verwerken wij
            voor de volgende doeleinden:{' '}
          </p>

          <ul>
            <li>
              Voor de praktische organisatie van de challenges, namelijk het
              registreren van je idee
            </li>
            <li>
              Om het mogelijk te maken dat bedrijven contact met je kunnen
              opnemen om verdere informatie te verkrijgen, of om een
              co-creatiesessie te kunnen starten
            </li>
            <li>
              Om je te kunnen contacteren i.v.m. onderzoeksdoeleinden, meer
              specifiek om je te kunnen vragen of je deel wil nemen aan een
              interview of enquête in het kader van dit onderzoek. Dit is
              uiteraard vrijblijvend.
            </li>
          </ul>

          <p>
            De persoonsgegevens die wij automatisch van jou verzamelen wanneer
            je onze website bezoekt, worden verwerkt voor de volgende
            doeleinden:{' '}
          </p>
          <ul>
            <li>
              Om onze website te beheren en verbeteren, zodat we de inhoud ervan
              op jouw kunnen afstemmen om je een meer gepersonaliseerde ervaring
              te bieden
            </li>
            <li>
              Voor het uitvoeren van statistische analyses met betrekking tot
              jouw bezoek- en klikgedrag.
            </li>
          </ul>

          <h2>Aan wie geven wij jouw persoonsgegevens door?</h2>
          <p>
            De gegevens die je bij het plaatsen van een idee op de website aan
            ons meedeelt, worden doorgegeven aan het bedrijf dat de challenge
            initieerde.{' '}
          </p>
          <p>
            Odisee maakt gebruik van cookies die afkomstig zijn van derde
            partijen (Google Analytics, Facebook, Instagram, Youtube, Twitter,
            Snapchat). Meer informatie hierover vind je in onze{' '}
            <Link to="/cookies">Cookieverklaring</Link>. Voor de cookies die
            deze ‘Social media’-partijen plaatsen en de data die zij hierbij
            verzamelen, verwijzen wij naar de verklaringen die deze partijen
            daarover op hun eigen websites geven. Let er op dat deze
            verklaringen regelmatig kunnen wijzigen en dat wij noch invloed
            kunnen uitoefenen op de inhoud van deze verklaringen, noch op de
            inhoud van de cookies van deze derde partijen.
          </p>

          <h2>Welke rechten heb je met betrekking tot je persoonsgegevens?</h2>
          <p>
            Je hebt verschillende rechten met betrekking tot je
            persoonsgegevens. Meer specifiek heb je het recht om:{' '}
          </p>
          <ul>
            <li>
              ons te vragen inzage te krijgen in de persoonsgegevens die we van
              jou verwerken en een kopie te vragen van de persoonsgegevens die
              we van jou hebben
            </li>
            <li>
              ons te vragen je persoonsgegevens te updaten of te corrigeren
            </li>
            <li>ons te vragen om je persoonsgegevens te wissen</li>
            <li>
              je toestemming voor het verwerken van je persoonsgegevens in te
              trekken (op voorwaarde dat de verwerking gebaseerd is op
              toestemming).
            </li>
          </ul>
          <br />
          <p>
            Indien je graag een van deze rechten wil uitoefenen kan je daarvoor
            een e –mail sturen naar{' '}
            <a href="mailto:davy.dewinne@odisee.be">davy.dewinne@odisee.be</a>
          </p>
          <p>
            Indien je niet tevreden bent over de manier waarop je jouw
            persoonsgegevens verwerken, heb je het recht om een klacht in te
            dienen bij de Gegevensbeschermingsautoriteit.
          </p>

          <h2>Aanpassingen</h2>
          <p>
            Deze Privacyverklaring kan van tijd tot tijd aangepast worden. Door
            regelmatig de website te raadplegen kan je op de hoogte blijven van
            wijzigingen.{' '}
          </p>

          <h2>Contactgegevens van de functionaris voor gegevensbescherming</h2>
          <p>
            Indien je verdere vragen of opmerkingen hebt in verband met de
            verwerking van uw persoonsgegevens, kan je contact opnemen met de
            functionaris voor gegevensbescherming van Odisee (<a href="mailto:privacy@odisee.be">
              privacy@odisee.be
            </a>).
          </p>
        </div>
        <Footer />
      </div>
    );
  }
}

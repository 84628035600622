import React, { Component } from 'react';
import type from 'prop-types';
import './Input.css';

export default class Input extends Component {
  render() {
    const { multiline, centeredlabel, invalid, ...newProps } = this.props;

    return (
      <div className={this.props.centeredlabel ? 'Input_centeredlabel' : null}>
        <label>
          <span className="Input_label">
            {this.props.label}
            {this.props.required ? (
              <span className="Input_required-star">*</span>
            ) : null}
          </span>
          {this.props.multiline ? (
            <textarea
              className={
                this.props.invalid
                  ? 'Input_ta-container Input_invalid'
                  : 'Input_ta-container'
              }
              required={false}
              rows="3"
              {...newProps}
              multiline="true"
            />
          ) : (
            <input
              type="text"
              className={
                this.props.invalid
                  ? 'Input_container Input_invalid'
                  : 'Input_container'
              }
              required={false}
              {...newProps}
            />
          )}
        </label>
      </div>
    );
  }
}

Input.propTypes = {
  centeredlabel: type.bool,
  label: type.string,
  multiline: type.bool,
  required: type.bool,
  invalid: type.bool
};

import React, { Component } from 'react';

import { Footer, Button, Link, ContentHeader } from '../../General';
import { ChallengeReply } from '../../Challenge';
import { graphQLFetch, drawBullet, sendAnalytics } from '../../../helpers';

import './ChallengeScreen.css';
import logo from '../../../images/logo.svg';
import queries from './queries';

import imgRaf1 from '../../../images/raf.png';
import imgRaf2 from '../../../images/raf_coffee_2.png';

class RAF extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newReplyReplyId: null,
      descriptionShort: '',
      showMore: false,
      challengeReplies: null
    };

    this.upvoteReply = this.upvoteReply.bind(this);
    this.addReplyReply = this.addReplyReply.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.getChallengeReplies = this.getChallengeReplies.bind(this);
  }

  componentDidMount() {
    this.getChallengeReplies(1);

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    // This element only exists after fetching data, that's why we check in componentDidUpdate
    if (window.location.hash === '#antwoorden') {
      const antwoorden = document.getElementById('antwoorden');
      if (antwoorden) {
        antwoorden.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'instant'
        });
      }
    }

    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  scrollToReplies() {
    const antwoorden = document.getElementById('antwoorden');
    if (antwoorden) {
      antwoorden.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  scrollToTop(e) {
    // window.scroll({ top: 0, behavior: 'smooth' });

    const top = document.getElementById('root');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  upvoteReply(id) {
    let votedFor = JSON.parse(localStorage.getItem('votedFor'));

    if (!votedFor) votedFor = [];
    if (votedFor.indexOf(id) !== -1) {
      return;
    }

    votedFor.push(id);
    localStorage.setItem('votedFor', JSON.stringify(votedFor));

    return graphQLFetch(queries.upvoteReplyQuery(id))
      .then(() => {
        this.getChallengeReplies(1);
      })
      .catch(err => {
        console.log(err);
      });
  }

  addReplyReply(challengeId, text) {
    return graphQLFetch(queries.addReplyReplyQuery(challengeId, text))
      .then(replyReplyJson => {
        this.getChallengeReplies(1, replyReplyJson.data.addReplyReply.id);
      })
      .catch(err => {
        console.log(err);
      });
  }

  getChallengeReplies(challengeId, newReplyReplyId = null) {
    graphQLFetch(queries.getChallengeRepliesQuery(challengeId)).then(json => {
      if (!json.data.challengeReplies) {
        throw Error('Geen antwoorden gevonden');
      }

      this.setState({
        challengeReplies: json.data.challengeReplies,
        newReplyReplyId
      });
    });
  }

  handleDescriptionChange(e) {
    this.setState({ descriptionShort: e.target.value });
  }

  render() {
    const challengeReplies = this.state.challengeReplies;

    if (this.state.error) return <p>Er is iets misgegaan :(</p>;

    return (
      <div>
        <div className="ChallengeScreen_ container">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="ChallengeScreen_header_logo"
            />
          </Link>

          <div className="ChallengeScreen_header">
            <p className="ChallengeScreen_header_title inactive_title">
              Hoe kan onze koffie een vaste plaats in jouw keuken veroveren?
            </p>

            <ChallengeReply
              key={1}
              reply={{
                id: 1,
                descriptionShort:
                  'Een koffieabonnement, om de 7-8 weken op het werkadres geleverd per (fiets)koerier, makkelijk opzegbaar en aanpasbaar. ',
                description: '',
                upvotes: 7,
                photoShortId: null,
                uploads: [],
                replyReplies: [
                  {
                    id: 1,
                    description:
                      'Geen slecht idee, maar de frequentie zou omhoog moeten voor de mensen die graag echt vers gebrande koffie drinken. Bv om de 2 weken een bezorging.',
                    formattedDate: null,
                    company: null
                  }
                ]
              }}
              active={false}
              final={true}
              votedFor={JSON.parse(localStorage.getItem('votedFor'))}
            />

            {/* <Input
              multiline
              placeholder="Mijn idee of mening hierover is..."
              onChange={this.handleDescriptionChange}
            />

            <Button
              iconRight="ion-ios-arrow-forward"
              link={{
                pathname: `/challenge/1/antwoord`,
                state: {
                  descriptionShort: this.state.descriptionShort
                }
              }}
            >
              Verstuur je idee
            </Button> */}

            {challengeReplies && challengeReplies.length === 0 ? null : (
              <a onClick={this.scrollToReplies}>
                Ontdek alle ingestuurde ideeën
              </a>
            )}
          </div>

          <div className="ChallengeScreen_two-cols">
            <div className="ChallengeScreen_col-left">
              <div className="ChallengeScreen_col-left_rotated">
                <img
                  src={imgRaf1}
                  alt={`RAF Koffie - decoratief.`}
                  className={'ChallengeScreen_col-left_img-rotated'}
                />
              </div>
            </div>
            <div>
              <ContentHeader>Waarom wou Raf dit weten?</ContentHeader>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()} Zodat mensen kunnen genieten van{' '}
                <strong>echte, goede en vers gebrande koffie</strong>.
              </p>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()} Ik wil de <strong>origine</strong> van de koffie
                tonen en <strong>laten proeven</strong>
              </p>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()} Om goede koffie <strong>bij jou thuis</strong> te
                brengen.
              </p>

              <p>
                Van een goede koffie moet je kunnen genieten. Om de betere
                koffies in huis te halen volstaat het doorgaans echter niet om
                even snel in de buurtsupermarkt binnen te springen.
              </p>
              <p>
                Bovendien beschouwt Raf Coffee koffie als een vers product dat
                een 8 tal weken lekker blijft.
                <br />
                Dit wil zeggen dat de klant geen grote stock kan inslaan en
                regelmatig, net voor het beëindigen van de vorige koffie een
                nieuwe bestelling door wil sturen opdat Raf Coffee opnieuw vers
                gebrande koffie kan opsturen.
              </p>
              <p className="ChallengeScreen_p-big">
                Koffie kopen zou nooit een karwei mogen zijn
              </p>
              <p>
                Daarom is Raf Coffee op zoek naar nieuwe manieren om koffies van
                hoge kwaliteit en met een unieke achtergrond moeiteloos{' '}
                <strong>tot bij jou thuis te krijgen</strong>.
              </p>
              <p>
                Heb je een idee dat ervoor kan zorgen dat klanten elke dag even
                gemakkelijk en betaalbaar van Rafs uitgebreide selectie van
                koffies kunnen genieten als van merk X uit de supermarkt? Laat
                het ons dan zeker weten!
              </p>
              {/* <p className="ChallengeScreen_idea-link">
                <a onClick={this.scrollToTop}>Ik heb een idee!</a>
              </p> */}
            </div>
          </div>

          {challengeReplies && challengeReplies.length > 0 ? (
            <ContentHeader id="antwoorden">
              Anderen dachten er zo over
            </ContentHeader>
          ) : null}
          <div className="ChallengeScreen_replies">
            {challengeReplies &&
              challengeReplies.map((reply, i) => {
                return this.state.showMore || i < 3 ? (
                  <ChallengeReply
                    key={reply.id}
                    reply={reply}
                    active={false}
                    votedFor={JSON.parse(localStorage.getItem('votedFor'))}
                    onUpvote={() => {
                      this.upvoteReply(reply.id);
                    }}
                    onReply={text => {
                      this.addReplyReply(reply.id, text);
                    }}
                    newReplyReplyId={this.state.newReplyReplyId}
                  />
                ) : null;
              })}
          </div>

          {!this.state.showMore &&
          challengeReplies &&
          challengeReplies.length > 0 &&
          challengeReplies.length > 3 ? (
            <Button
              onClick={() => this.setState({ showMore: true })}
              iconRight="ion-ios-arrow-down"
            >
              Toon alle ideeën
            </Button>
          ) : null}

          <ContentHeader>Over Raf</ContentHeader>
          <div className="ChallengeScreen_two-cols">
            <div>
              <div className="ChallengeScreen_light-text">
                <p>
                  Ik heb <strong>jarenlange ervaring</strong> in de aankoop van
                  groene koffie en de{' '}
                  <strong>ontwikkeling van koffiemelanges</strong>.
                </p>
                <p>
                  RAF COFFEE is geboren vanuit deze achtergrond en een groeiende
                  koffiefascinatie. Het is mijn eigen merk van{' '}
                  <strong>artisanale, vers gebrande, kwaliteitsvolle</strong> en
                  geduldig <strong>geselecteerde</strong> groene koffies.
                </p>
                <p>
                  Je vindt informatie op{' '}
                  <a
                    href="http://rafcoffee.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    deze website
                  </a>{' '}
                  en kan mij steeds vragen stellen over alle aspecten van
                  koffie. Ik zal je met plezier informeren en adviseren.
                </p>
                <p>
                  Met RAF COFFEE wil ik vooral{' '}
                  <strong>koffie maken die je bijzonder goed smaakt</strong> .
                </p>
                <p>
                  <i className="icon ion-ios-globe" /> &nbsp;Regio: Gent
                </p>
                <p>
                  <i className="icon ion-ios-link" /> &nbsp;
                  <a
                    href="http://rafcoffee.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    rafcoffee.be
                  </a>
                </p>
              </div>
            </div>
            <div className="ChallengeScreen_col-right">
              <img
                src={imgRaf2}
                alt="Decoratieve foto RAF Coffee."
                className="ChallengeScreen_col-right_img"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default RAF;

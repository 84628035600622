import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { Input, Button, Link, Spinner, H2 } from '../../../General';
import { graphQLFetch } from '../../../../helpers';
import config from '../../../../config';
import './LoginScreen.css';
import queries from './queries';

export default class CompanyLoginScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: null,
      password: '',
      isLoading: false,
      fail: false,
      shouldRedirect: false
    };

    this.login = this.login.bind(this);
    this.handlePasswordChanged = this.handlePasswordChanged.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    const { match } = this.props;
    const paramName = match.params.name;
    const token = localStorage.getItem('token');

    this.setState({ isLoading: true });

    // Check if token is valid
    graphQLFetch(queries.validateTokenQuery(token)).then(json => {
      if (json.data.validateToken) {
        // Redirect
        this.setState({ shouldRedirect: true, isLoading: false });
        return;
      }

      // Otherwise fetch company data
      graphQLFetch(queries.getCompanyQuery(paramName))
        .then(json => {
          this.setState({ company: json.data.company, isLoading: false });
        })
        .catch(err => {
          console.log(err);
          this.setState({ isLoading: false });
        });
    });
  }

  handlePasswordChanged(e) {
    this.setState({ password: e.target.value });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.login();
    }
  }

  login() {
    this.setState({ isLoading: true, fail: false });

    fetch(config.server + '/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        name: this.state.company.name,
        password: this.state.password
      })
    })
      .then(res => {
        if (res.status === 400) {
          // fail
          this.setState({ isLoading: false, fail: true });
        }
        if (res.status === 200) {
          res.json().then(json => {
            console.log(json.token);
            localStorage.setItem('token', json.token);
            this.setState({ isLoading: false, shouldRedirect: true });
          });
        }
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false, fail: true });
      });
  }

  render() {
    const redirect = this.state.company
      ? this.state.company.name
      : this.props.match.params.name;

    if (this.state.shouldRedirect)
      return <Redirect to={`/bedrijf/${redirect}/dashboard`} />;
    if (this.state.isLoading)
      return (
        <div className="centered-container">
          <Spinner />
        </div>
      );
    if (!this.state.company) {
      return (
        <div className="CompanyLoginScreen_ centered-container">
          <p>Bedrijf werd niet gevonden</p>
          <Link to="/">Terug naar home</Link>
        </div>
      );
    }

    return (
      <div className="CompanyLoginScreen_ centered-container">
        <H2>{`Welkom, ${this.state.company.name}`}</H2>
        <Input
          type="password"
          label="Gelieve uw wachtwoord in te geven"
          value={this.state.password}
          onChange={this.handlePasswordChanged}
          onKeyPress={this.handleKeyPress}
          centeredlabel
        />
        {this.state.fail ? (
          <p className="error">Inloggen mislukt. Controleer uw wachtwoord.</p>
        ) : null}
        <Button onClick={this.login}>Log in</Button>
      </div>
    );
  }
}

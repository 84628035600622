import React, { Component } from 'react';
import type from 'prop-types';
import './ChallengeExtended.css';
import { Input, Button } from '../../General';
import config from '../../../config';

export default class ChallengeExtended extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputVal: ''
    };

    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidUpdate() {
    // This screen updated and rerenderd all replies. The newly added reply has a unique ID, scroll to it.
    const newReplyReply = document.getElementById(
      'ChallengeExtended_replies_new'
    );
    if (newReplyReply) {
      newReplyReply.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });

      // Not new anymore; Prevents page from scrolling when typing in input field
      newReplyReply.removeAttribute('id');
    }
  }

  handleOnChange(e) {
    this.setState({ inputVal: e.target.value });
  }

  handleSubmit() {
    this.props.onReply(this.state.inputVal);
    this.setState({ inputVal: '' });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.props.onReply(this.state.inputVal);
      this.setState({ inputVal: '' });
    }
  }

  prevent(e) {
    e.stopPropagation();
  }

  render() {
    const reply = this.props.reply;

    return (
      <div className="ChallengeExtended_container" onClick={this.prevent}>
        <a
          className="ChallengeExtended_close"
          onClick={this.props.onClose}
        >
          <i className="icon ion-ios-close" />
        </a>

        <pre>{reply.descriptionShort}</pre>

        {reply.description && reply.description !== '' ? (
          <pre>{reply.description}</pre>
        ) : null}

        {reply.photoShortId && reply.photoShortId !== '' ? (
          <a href={`${config.server}/uploads/${reply.photoShortId}`}>
            <img
              className="ChallengeExtended_photo"
              src={`${config.server}/uploads/${reply.photoShortId}`}
              alt="Foto bij dit antwoord"
            />
          </a>
        ) : null}

        {reply.uploads && reply.uploads.length > 0 ? (
          <div className="ChallengeExtended_attachments">
            <h3>Bijlagen</h3>
            {reply.uploads.map((att, i) => {
              return (
                <span key={att.shortid}>
                  <a href={`${config.server}/uploads/${att.shortid}`}>
                    {att.originalname}
                  </a>
                  {i !== reply.uploads.length - 1 ? ', ' : null}
                </span>
              );
            })}
          </div>
        ) : null}

        {reply.replyReplies && reply.replyReplies.length > 0 ? (
          <div className="ChallengeExtended_replies">
            <h3>
              {reply.replyReplies.length} reactie
              {reply.replyReplies.length !== 1 ? 's' : ''} van gebruikers
            </h3>
            {reply.replyReplies.map(rep => {
              return (
                <div
                  id={
                    this.props.newReplyReplyId === rep.id
                      ? 'ChallengeExtended_replies_new'
                      : null
                  }
                  className={`${
                    this.props.newReplyReplyId === rep.id
                      ? 'ChallengeExtended_replies_new'
                      : 'ChallengeExtended_replies_item'
                  } ${
                    rep.company ? 'ChallengeExtended_replies_by_company' : null
                  }`}
                  key={rep.id}
                >
                  <div>
                    <p
                      className={
                        rep.company
                          ? 'ChallengeExtended_replies_by_company_description'
                          : 'ChallengeExtended_replies_item_description'
                      }
                    >
                      {rep.description}
                      <span className="ChallengeExtended_replies_item_description_date">
                        {rep.formattedDate}
                      </span>
                    </p>
                    {rep.company ? (
                      <span className="ChallengeExtended_replies_by_company_name">
                        -{rep.company.name}
                      </span>
                    ) : null}
                  </div>
                </div>
              );
            })}
          </div>
        ) : null}

        {this.props.active ? (
          // !this.props.forAdmins ? (
          <div className="ChallengeExtended_reply">
            <h3>Neem deel aan het gesprek</h3>
            <div className="ChallengeExtended_reply_input">
              <Input
                onChange={this.handleOnChange}
                onKeyPress={this.handleKeyPress}
                value={this.state.inputVal}
                placeholder="Ik vind dit idee..."
              />
              <Button onClick={this.handleSubmit}>Reageer</Button>
            </div>
          </div>
        ) : // ) : null
        null}
      </div>
    );
  }
}

ChallengeExtended.propTypes = {
  newReplyReplyId: type.number,
  forAdmins: type.bool
};

import React, { Component } from 'react';
import type from 'prop-types';

import './ChallengeTitle.css';

export default class Title extends Component {
  render() {
    return (
      <p
        className={
          this.props.extraClassNames
            ? ['ChallengeTitle_', ...this.props.extraClassNames].join(' ')
            : 'ChallengeTitle_'
        }
      >
        {this.props.children}
      </p>
    );
  }
}

Title.propTypes = {
  children: type.string,
  extraClassNames: type.arrayOf(type.string)
};

const getCompanyQuery = name => {
  return {
    query: `
      {
        company(name: "${name}") {
          id
          name
        }
      }
    `
  };
};

const validateTokenQuery = token => {
  return {
    query: `
      {
        validateToken(token: "${token}")
      }
    `
  };
};

export default { getCompanyQuery, validateTokenQuery };

import React, { Component } from 'react';
import { Link } from '../../General';
import { sendAnalytics } from '../../../helpers';
import './EmailSentScreen.css';

export default class EmailSentScreen extends Component {
  componentDidMount() {
    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    return (
      <div className="EmailSentScreen_ centered-container">
        <p>Je bericht is succesvol verzonden!</p>
        <p>
          <Link to="/">
            <i className="icon ion-ios-arrow-back" /> Terug naar challenges
          </Link>
        </p>
      </div>
    );
  }
}

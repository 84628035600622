const getCompanyDataQuery = token => {
  return {
    query: `
      {
        companyData(token: "${token}") {
          company {
            id
            name
          }
          challenges {
            id
            title
            description
            replyCount

            challengeReplies {
              id
              descriptionShort
              description
              upvotes
              photoShortId
              email
              formattedDate

              uploads {
                shortid
                originalname
              }

              replyReplies {
                id
                description
                formattedDate

                company {
                  id
                  name
                  description
                  region
                  siteUrl
                }
              }
            }
          }
        }
      }
    `
  };
};

export default {
  getCompanyDataQuery
};

/*** GraphQL Queries ***/

const getChallengeQuery = paramId => {
  return {
    query: `
      {
        challenge(id: ${paramId}) {
          id
          title

          company{
            id
            name
          }
        }
      }
    `
  };
};

const addChallengeQuery = (state, formattedAttachments) => {
  const hasPhoto = state.photo.shortid
    ? `photoShortId: "${state.photo.shortid}"`
    : ``;

  const sanitizedDescriptionShort = state.descriptionShort; //.replace('"', '"');
  const sanitizedDescription = state.description; //.replace('"', '"');

  return {
    query: `
      mutation {
        addChallengeReply(
          challengeId: ${state.challenge.id}, 
          descriptionShort: """ ${sanitizedDescriptionShort} """, 
          description: """ ${sanitizedDescription} """,
          email: "${state.email}",
          emailNotifications: ${state.agreeNotifications},
          ${hasPhoto}
          attachments: [${formattedAttachments}]
        ) {
          id
          title
          description
        }
      }
    `
  };
};

export default {
  getChallengeQuery,
  addChallengeQuery
};

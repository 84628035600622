import React from 'react';
import { Link } from 'react-router-dom';
import { sendAnalytics } from '../../../helpers';
import './SurveyScreen.css';

export default class SurveyScreen extends React.Component {
  componentDidMount() {
    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    return (
      <div className="SurveyScreen_container centered-container">
        <h3>Bedankt voor je input!</h3>

        <p>
          Mijnidee.be maakt deel uit van een onderzoek van de Odisee hogeschool.
          <br />
          Help ons onderzoek om KMO's klantgericht te helpen innoveren.
        </p>

        <h3 className="SurveyScreen_midtitle">
          Beantwoord 6 korte vragen en help ons onderzoek
        </h3>

        <div>
          <a
            href="https://odisee.eu.qualtrics.com/jfe/form/SV_5grnB1QBDs41hqJ"
            className="SurveyScreen_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            Naar de enquête
          </a>{' '}
          <p>(6 vragen - 1 minuut)</p>
          <p>
            No thanks, ik wil <Link to="/">terug naar de site</Link>
          </p>
        </div>
      </div>
    );
  }
}

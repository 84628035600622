import React, { Component } from 'react';
import { sendAnalytics } from '../../../helpers';
import './NotFoundScreen.css';

export default class NotFoundScreen extends Component {
  componentDidMount() {
    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    return (
      <div className="NotFoundScreen_container">
        <p>
          Oeps!<br />Deze pagina kon niet gevonden worden...
        </p>
      </div>
    );
  }
}

import React from 'react';
import { detect } from 'detect-browser';
import reactGA from 'react-ga';
import config from '../config';

function graphQLFetch(query) {
  return fetch(config.graphQL, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json ' },
    body: JSON.stringify(query)
  }).then(res => {
    return res.json();
  });
}

function drawBullet() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.37081 16.269C9.0639 16.5759 8.57282 16.5759 8.2659 16.269L7.01367 15.0167C6.70675 14.7098 6.70675 14.2187 7.01367 13.9118L10.7826 10.1429L7.01367 6.37388C6.70675 6.06696 6.70675 5.57589 7.01367 5.26897L8.2659 4.01674C8.57282 3.70982 9.0639 3.70982 9.37081 4.01674L14.9445 9.5904C15.2514 9.89732 15.2514 10.3884 14.9445 10.6953L9.37081 16.269ZM19.4255 10.1429C19.4255 4.9375 15.2023 0.714285 9.99693 0.714285C4.79157 0.714285 0.568359 4.9375 0.568359 10.1429C0.568359 15.3482 4.79157 19.5714 9.99693 19.5714C15.2023 19.5714 19.4255 15.3482 19.4255 10.1429Z"
        fill="#FFB638"
      />
    </svg>
  );
}

function sendAnalytics(props) {
  const browser = detect();
  if (props && props.location && props.location.pathname) {
    let browserQ;

    if (browser) {
      browserQ = browser;
    }

    const query = {
      query: `
        mutation {
          sendAnalytics(
            pathname: "${props.location.pathname}", 
            browserName: "${browserQ.name}", 
            browserVersion: "${browserQ.version}", 
            os: "${browserQ.os}"
          )
        }
      `
    };

    return graphQLFetch(query).then(() => {
      reactGA.pageview(props.location.pathname);
    });
  }
}

export { graphQLFetch, drawBullet, sendAnalytics };

import React, { Component } from 'react';

import { Footer, Button, Link, Input, ContentHeader } from '../../General';
import { ChallengeReply } from '../../Challenge';
import { graphQLFetch, drawBullet, sendAnalytics } from '../../../helpers';

import './ChallengeScreen.css';
import logo from '../../../images/logo.svg';
import queries from './queries';

import img1 from '../../../images/challenges/confidas_1.png';
import img2 from '../../../images/challenges/confidas_cropped2.png';
import img3 from '../../../images/challenges/confidas_2.png';

class Confidas1 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newReplyReplyId: null,
      descriptionShort: '',
      showMore: false,
      challengeReplies: null
    };

    this.upvoteReply = this.upvoteReply.bind(this);
    this.addReplyReply = this.addReplyReply.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.getChallengeReplies = this.getChallengeReplies.bind(this);
  }

  componentDidMount() {
    this.getChallengeReplies(4);

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    // This element only exists after fetching data, that's why we check in componentDidUpdate
    if (window.location.hash === '#antwoorden') {
      const antwoorden = document.getElementById('antwoorden');
      if (antwoorden) {
        antwoorden.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'instant'
        });
      }
    }

    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  scrollToReplies() {
    const antwoorden = document.getElementById('antwoorden');
    if (antwoorden) {
      antwoorden.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  scrollToTop(e) {
    // window.scroll({ top: 0, behavior: 'smooth' });

    const top = document.getElementById('root');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  upvoteReply(id) {
    let votedFor = JSON.parse(localStorage.getItem('votedFor'));

    if (!votedFor) votedFor = [];
    if (votedFor.indexOf(id) !== -1) {
      return;
    }

    votedFor.push(id);
    localStorage.setItem('votedFor', JSON.stringify(votedFor));

    return graphQLFetch(queries.upvoteReplyQuery(id))
      .then(() => {
        this.getChallengeReplies(4);
      })
      .catch(err => {
        console.log(err);
      });
  }

  addReplyReply(challengeId, text, token) {
    if (token) {
      return graphQLFetch(
        queries.addReplyReplyQueryByCompany(challengeId, text, token)
      )
        .then(replyReplyJson => {
          this.getChallengeReplies(4, replyReplyJson.data.addReplyReply.id);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      return graphQLFetch(queries.addReplyReplyQuery(challengeId, text))
        .then(replyReplyJson => {
          this.getChallengeReplies(4, replyReplyJson.data.addReplyReply.id);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  getChallengeReplies(challengeId, newReplyReplyId = null) {
    graphQLFetch(queries.getChallengeRepliesQuery(challengeId)).then(json => {
      if (!json.data.challengeReplies) {
        throw Error('Geen antwoorden gevonden');
      }

      this.setState({
        challengeReplies: json.data.challengeReplies,
        newReplyReplyId
      });
    });
  }

  handleDescriptionChange(e) {
    this.setState({ descriptionShort: e.target.value });
  }

  render() {
    const challengeReplies = this.state.challengeReplies;

    if (this.state.error) return <p>Er is iets misgegaan :(</p>;

    return (
      <div>
        <div className="ChallengeScreen_ container">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="ChallengeScreen_header_logo"
            />
          </Link>

          <div className="ChallengeScreen_header">
            <p className="ChallengeScreen_header_title">
              Hoe zetten we onze zoete lekkernij perfect in de kijker?
            </p>

            <Input
              multiline
              placeholder="Mijn idee of mening hierover is..."
              onChange={this.handleDescriptionChange}
            />

            <Button
              iconRight="ion-ios-arrow-forward"
              link={{
                pathname: `/challenge/4/antwoord`,
                state: {
                  descriptionShort: this.state.descriptionShort
                }
              }}
            >
              Verstuur je idee
            </Button>

            {challengeReplies && challengeReplies.length === 0 ? null : (
              <a onClick={this.scrollToReplies}>
                Ontdek anderen hun ideeën
              </a>
            )}
          </div>

          <div className="ChallengeScreen_two-cols">
            <div className="ChallengeScreen_col-left">
              <div className="ChallengeScreen_col-left_rotated">
                <img
                  src={img1}
                  alt="Zerline van Confidas."
                  className={'ChallengeScreen_col-left_img-rotated'}
                />
              </div>
            </div>
            <div>
              <ContentHeader>
                Waarom willen Luc & Zerline van Confidas dit weten?
              </ContentHeader>
              <p>
                Pâtes de fruits weten al eeuwenlang levensgenieters te bekoren.
                <br />
                <br />
                Vandaag de dag wordt de markt echter overspoeld door tal van
                ander zoete alternatieven, waardoor deze lekkernij soms over het
                hoofd gezien wordt.
                <br />
                <br />
                Onterecht, want onze ambachtelijke pâtes de fruits bestaan voor
                50 tot 70% uit fruit. <br />
                <br />
                Ze bevatten{' '}
                <strong>
                  enkel natuurlijke ingrediënten, zonder gelatine, artificiële
                  kleur- of smaakstoffen of bewaarmiddelen.
                </strong>
                <br />
                <br />
                We zijn dan ook op zoek naar ideeën om onze pâtes de fruits
                terug de <strong>bekendheid en populariteit</strong> te geven
                die ze verdienen. <br />
                <br />
                Heb je voorstellen om ons dat te helpen realiseren? Laat het ons
                dan hier zeker weten!
                <br />
                <br />
              </p>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()}
                &nbsp; Een goed idee belonen wij graag met een heerlijk
                pakketje!
              </p>

              <p className="ChallengeScreen_idea-link">
                <a onClick={this.scrollToTop}>
                  Ik heb een idee!
                </a>
              </p>
            </div>
          </div>

          {challengeReplies && challengeReplies.length > 0 ? (
            <ContentHeader id="antwoorden">
              Anderen denken er zo over
            </ContentHeader>
          ) : null}
          <div className="ChallengeScreen_replies">
            {challengeReplies &&
              challengeReplies.map((reply, i) => {
                return this.state.showMore || i < 3 ? (
                  <ChallengeReply
                    key={reply.id}
                    reply={reply}
                    active={true}
                    votedFor={JSON.parse(localStorage.getItem('votedFor'))}
                    onUpvote={() => {
                      this.upvoteReply(reply.id);
                    }}
                    onReply={text => {
                      this.addReplyReply(
                        reply.id,
                        text,
                        localStorage.getItem('token')
                      );
                    }}
                    newReplyReplyId={this.state.newReplyReplyId}
                  />
                ) : null;
              })}
          </div>

          {!this.state.showMore &&
          challengeReplies &&
          challengeReplies.length > 0 &&
          challengeReplies.length > 3 ? (
            <Button
              onClick={() => this.setState({ showMore: true })}
              iconRight="ion-ios-arrow-down"
            >
              Toon alle ideeën
            </Button>
          ) : null}

          <ContentHeader>Over Confidas</ContentHeader>
          <img
            src={img2}
            alt="Lekkernijen van Confidas."
            className="ChallengeScreen_img-confidas"
          />

          <div className="ChallengeScreen_two-cols">
            <div>
              <div className="ChallengeScreen_light-text">
                <p>
                  Confidas is sinds 1982 producent van{' '}
                  <strong>pâtes de fruits</strong>. <br />
                  Pâtes de fruits zijn een hoogwaardig confiserieproduct met een
                  hoog fruitgehalte. <br /> <br />
                  Confidas blijft trouw aan de originele receptuur door <br />
                  1. een minimum van <strong>50% fruitgehalte</strong> te
                  garanderen <br />
                  2. enkel <strong>natuurlijke ingrediënten</strong> te
                  gebruiken (geen bewaarmiddelen, geen artificiële smaak- en
                  kleurstoffen) <br />
                  3. <strong>geen dierlijke producten</strong> toe te voegen
                  (geen gelatine).
                </p>
                <p>
                  <i className="icon ion-ios-globe" /> &nbsp;Regio: Gent
                </p>
                <p>
                  <i className="icon ion-ios-link" /> &nbsp;
                  <a
                    href="http://confidas.be/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    confidas.be
                  </a>
                </p>
              </div>
            </div>
            <div className="ChallengeScreen_col-right">
              <p>
                Het bedrijf is marktleider in België.
                <br />
                75% van de omzet wordt internationaal gerealiseerd met Frankrijk
                als belangrijkste markt. <br />
                Onze producten worden voornamelijk als private label verkocht.{' '}
              </p>
              <p>
                Sinds 2019 kunnen klanten ook consumentenverpakkingen aankopen
                onder het merk ‘Confidas’.
              </p>
              <br />
              <br />
              <img
                src={img3}
                alt="Confidas"
                className="ChallengeScreen_col-right_img"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Confidas1;

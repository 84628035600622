import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css';
import { createRequire } from 'module';
export default class Footer extends Component {
  render() {
    let logo = require("../../../images/odisee_2019_helderwit_0.png");
    return (
      <div className="Footer_">
        <img src={logo} className="footerImg" alt="" />
        <div className="container">
          <p>
            <Link to="/">Mijnidee.be</Link> is een project van&nbsp;
            <a
              href="http://odisee.be"
              target="_blank"
              rel="noopener noreferrer"
            >
              Odisee
            </a>{' '}
            gesteund met PWO-middelen.
          </p>

          <p>
            <a
              href="https://www.odisee.be/nl/gent"
              target="_blank"
              rel="noopener noreferrer"
            >
              Contact
            </a>
          </p>

          <p>
            <Link to="/privacy">Privacy</Link>
          </p>

          <p>&copy; 2018 Odisee</p>
        </div>
      </div>
    );
  }
}

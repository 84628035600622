import React, { Component } from 'react';

import { Footer, Button, Link, Input, ContentHeader } from '../../General';
import { ChallengeReply } from '../../Challenge';
import { graphQLFetch, drawBullet, sendAnalytics } from '../../../helpers';

import './ChallengeScreen.css';
import logo from '../../../images/logo.svg';
import queries from './queries';

import img1 from '../../../images/challenges/3coach_cropped.png';
import img2 from '../../../images/challenges/3coach3_cropped.png';

class Coach extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newReplyReplyId: null,
      descriptionShort: '',
      showMore: false,
      challengeReplies: null
    };

    this.upvoteReply = this.upvoteReply.bind(this);
    this.addReplyReply = this.addReplyReply.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.getChallengeReplies = this.getChallengeReplies.bind(this);
  }

  componentDidMount() {
    this.getChallengeReplies(5);

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    // This element only exists after fetching data, that's why we check in componentDidUpdate
    if (window.location.hash === '#antwoorden') {
      const antwoorden = document.getElementById('antwoorden');
      if (antwoorden) {
        antwoorden.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'instant'
        });
      }
    }

    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  scrollToReplies() {
    const antwoorden = document.getElementById('antwoorden');
    if (antwoorden) {
      antwoorden.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  scrollToTop(e) {
    // window.scroll({ top: 0, behavior: 'smooth' });

    const top = document.getElementById('root');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  upvoteReply(id) {
    let votedFor = JSON.parse(localStorage.getItem('votedFor'));

    if (!votedFor) votedFor = [];
    if (votedFor.indexOf(id) !== -1) {
      return;
    }

    votedFor.push(id);
    localStorage.setItem('votedFor', JSON.stringify(votedFor));

    return graphQLFetch(queries.upvoteReplyQuery(id))
      .then(() => {
        this.getChallengeReplies(5);
      })
      .catch(err => {
        console.log(err);
      });
  }

  addReplyReply(challengeId, text, token) {
    if (token) {
      return graphQLFetch(
        queries.addReplyReplyQueryByCompany(challengeId, text, token)
      )
        .then(replyReplyJson => {
          this.getChallengeReplies(5, replyReplyJson.data.addReplyReply.id);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      return graphQLFetch(queries.addReplyReplyQuery(challengeId, text))
        .then(replyReplyJson => {
          this.getChallengeReplies(5, replyReplyJson.data.addReplyReply.id);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  getChallengeReplies(challengeId, newReplyReplyId = null) {
    graphQLFetch(queries.getChallengeRepliesQuery(challengeId)).then(json => {
      if (!json.data.challengeReplies) {
        throw Error('Geen antwoorden gevonden');
      }

      this.setState({
        challengeReplies: json.data.challengeReplies,
        newReplyReplyId
      });
    });
  }

  handleDescriptionChange(e) {
    this.setState({ descriptionShort: e.target.value });
  }

  render() {
    const challengeReplies = this.state.challengeReplies;

    if (this.state.error) return <p>Er is iets misgegaan :(</p>;

    return (
      <div>
        <div className="ChallengeScreen_ container">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="ChallengeScreen_header_logo"
            />
          </Link>

          <div className="ChallengeScreen_header">
            <p className="ChallengeScreen_header_title">
              Wat overtuigt jou om fruit-sportrepen als energiebron te gebruiken
              tijdens het duursporten?
            </p>

            <Input
              multiline
              placeholder="Mijn idee of mening hierover is..."
              onChange={this.handleDescriptionChange}
            />

            <Button
              iconRight="ion-ios-arrow-forward"
              link={{
                pathname: `/challenge/5/antwoord`,
                state: {
                  descriptionShort: this.state.descriptionShort
                }
              }}
            >
              Verstuur je idee
            </Button>

            {challengeReplies && challengeReplies.length === 0 ? null : (
              <a onClick={this.scrollToReplies}>
                Ontdek anderen hun ideeën
              </a>
            )}
          </div>

          <div className="ChallengeScreen_two-cols">
            <div className="ChallengeScreen_col-left">
              <div className="ChallengeScreen_col-left_rotated">
                <img
                  src={img1}
                  alt="Christian, Wim, Bart en Bastien van 3COACH."
                  className={'ChallengeScreen_col-left_img-rotated'}
                />
              </div>
            </div>
            <div>
              <ContentHeader>
                Waarom willen Christian, Wim, Bart en Bastien van 3COACH.BE dit
                weten?
              </ContentHeader>
              <p>
                ● Fruit-sportrepen bestaan uit minimum 50% fruit. Ze zijn een
                bron van natuurlijke suikers, makkelijk verteerbaar en geschikt
                om een energiedip tegen te gaan <br /> <br />● Fruit-sportrepen
                bestaan tot 80% uit koolhydraten, ze bevatten enkel natuurlijke
                ingrediënten (geen artificiële kleur en smaakstoffen), geen
                vetten, granen of eiwitten. Ze zijn lekker van smaak en niet
                belastend voor de maag.
                <br /> <br />● In samenwerking met een Gentse producent van
                sportvoeding peilen we naar jouw noden en ervaringen als
                duursporter met betrekking tot sportvoeding.
                <br /> <br />● Fruit-sportrepen zijn een interessant maar weinig
                gebruikt alternatief voor gels.
              </p>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()}
                &nbsp; Geef jouw mening en maak kans op een pakket
                fruit-sportrepen van deze Gentse producent!
              </p>

              <p className="ChallengeScreen_idea-link">
                <a onClick={this.scrollToTop}>
                  Ik heb een idee!
                </a>
              </p>
            </div>
          </div>

          {challengeReplies && challengeReplies.length > 0 ? (
            <ContentHeader id="antwoorden">
              Anderen denken er zo over
            </ContentHeader>
          ) : null}
          <div className="ChallengeScreen_replies">
            {challengeReplies &&
              challengeReplies.map((reply, i) => {
                return this.state.showMore || i < 3 ? (
                  <ChallengeReply
                    key={reply.id}
                    reply={reply}
                    active={true}
                    votedFor={JSON.parse(localStorage.getItem('votedFor'))}
                    onUpvote={() => {
                      this.upvoteReply(reply.id);
                    }}
                    onReply={text => {
                      this.addReplyReply(
                        reply.id,
                        text,
                        localStorage.getItem('token')
                      );
                    }}
                    newReplyReplyId={this.state.newReplyReplyId}
                  />
                ) : null;
              })}
          </div>

          {!this.state.showMore &&
          challengeReplies &&
          challengeReplies.length > 0 &&
          challengeReplies.length > 3 ? (
            <Button
              onClick={() => this.setState({ showMore: true })}
              iconRight="ion-ios-arrow-down"
            >
              Toon alle ideeën
            </Button>
          ) : null}

          <ContentHeader>Wie is 3COACH.BE?</ContentHeader>
          <div className="ChallengeScreen_two-cols">
            <div>
              <div className="ChallengeScreen_light-text">
                <p>
                  Wij zijn een team van coaches die jou als duursporters
                  persoonlijk begeleiden bij het behalen van jouw sportieve
                  doelstellingen. <br />
                  Jij krijgt een <strong>persoonlijk begeleiding</strong>: samen
                  streven wij naar een concrete, door jou bepaalde doelstelling
                  via trainings- en wedstrijdprogramma’s op jouw maat. <br />
                  Jij wordt opgevolgd via een intensieve wisselwerking en
                  feedback, field coachings en inspanningstesten.
                  <br /> <br />
                  Wij zijn er steeds op uit om jou de meest geschikte voeding
                  aan te bevelen voorafgaand, tijdens en na de prestatie. <br />
                  De samenwerking met dit Gents bedrijf dat fruit-sportrepen als
                  energiebron tijdens duurtrainingen en wedstrijden produceert
                  is voor ons een fijne opportuniteit om jou hierin op een
                  unieke manier te betrekken.
                </p>
                <p>
                  <i className="icon ion-ios-globe" /> &nbsp;Regio: Gent
                </p>
                <p>
                  <i className="icon ion-ios-link" /> &nbsp;
                  <a
                    href="http://3coach.be"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    3coach.be
                  </a>
                </p>
              </div>
            </div>
            <div className="ChallengeScreen_col-right">
              <img
                src={img2}
                alt="NewChallenge2"
                className="ChallengeScreen_col-right_img"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Coach;

import Navigator from './Navigator';
import ScrollToTop from './ScrollToTop';
import HomeScreen from './HomeScreen/HomeScreen';
import AnswerScreen from './AnswerScreen/AnswerScreen';
import NotFoundScreen from './NotFoundScreen/NotFoundScreen';
import EmailSentScreen from './EmailSentScreen/EmailSentScreen';

import RAF from './Challenges/RAF';
import Zonen09 from './Challenges/Zonen09';
import AtelierWatt from './Challenges/AtelierWatt';
import Coach from './Challenges/Coach';
import Confidas1 from './Challenges/Confidas1';
import VDCalseyde from './Challenges/VDCalseyde';

import LoginScreen from './Company/LoginScreen/LoginScreen';
import DashboardScreen from './Company/DashboardScreen/DashboardScreen';
import PasswordScreen from './Company/PasswordScreen/PasswordScreen';
import Widget from './Company/Widget/Widget';
import PrivacyScreen from './PrivacyScreen/PrivacyScreen';
import CookieScreen from './PrivacyScreen/CookieScreen';
import SurveyScreen from './SurveyScreen/SurveyScreen';

const Company = {
  LoginScreen,
  DashboardScreen,
  PasswordScreen,
  Widget
};

export {
  Navigator,
  HomeScreen,
  AnswerScreen,
  NotFoundScreen,
  ScrollToTop,
  EmailSentScreen,
  Company,
  AtelierWatt,
  Coach,
  Confidas1,
  VDCalseyde,
  RAF,
  Zonen09,
  PrivacyScreen,
  CookieScreen,
  SurveyScreen
};

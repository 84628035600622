import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';

import { Input, Button, Link, Spinner, H2 } from '../../../General';
import { graphQLFetch, sendAnalytics } from '../../../../helpers';
import config from '../../../../config';
import './PasswordScreen.css';
import queries from './queries';

export default class PasswordScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      company: null,
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      isLoading: false,
      shouldRedirect: false,
      message: null
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.changePassword = this.changePassword.bind(this);
  }

  componentDidMount() {
    const token = localStorage.getItem('token');

    this.setState({ isLoading: true });

    // Check if token is valid
    graphQLFetch(queries.validateTokenQuery(token)).then(json => {
      if (!json.data.validateToken) {
        this.setState({ shouldRedirect: true, isLoading: false });
      } else {
        this.setState({ isLoading: false });
      }
    });

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  handleKeyPress(e) {
    if (e.key === 'Enter') {
      this.changePassword();
    }
  }

  changePassword() {
    if (!this.state.oldPassword || this.state.oldPassword === '')
      return this.setState({
        message: 'Gelieve het oude wachtword in te vullen'
      });
    if (!this.state.newPassword || this.state.newPassword === '')
      return this.setState({
        message: 'Gelieve een nieuw wachtwoord mee te geven'
      });
    if (!this.state.newPasswordRepeat || this.state.newPasswordRepeat === '')
      return this.setState({
        message: 'Gelieve het nieuwe wachtwoord te herhalen'
      });
    if (this.state.newPassword !== this.state.newPasswordRepeat)
      return this.setState({ message: 'Wachtwoorden komen niet overeen' });

    this.setState({ isLoading: true, message: null });
    const token = localStorage.getItem('token');

    fetch(config.server + '/changepassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        token,
        oldPassword: this.state.oldPassword,
        newPassword: this.state.newPasswordRepeat
      })
    })
      .then(res => {
        res.json().then(json => {
          if (res.status === 200) {
            localStorage.removeItem('token');
            return this.setState({ isLoading: false, shouldRedirect: true });
          }

          this.setState({ isLoading: false, message: json.message });
        });
      })
      .catch(err => {
        this.setState({ isLoading: false, message: err.message });
        console.log(err);
      });
  }

  render() {
    const paramName = this.props.match.params.name;
    if (this.state.shouldRedirect)
      return <Redirect to={`/bedrijf/${paramName}`} />;
    if (this.state.isLoading)
      return (
        <div className="centered-container">
          <Spinner />
        </div>
      );

    return (
      <div className="PasswordScreen_ centered-container">
        <H2>Wachtwoord wijzigen</H2>
        <p>
          Was dit niet de bedoeling?<br />
          <Link to={`/bedrijf/${paramName}`}>Keer terug</Link>
        </p>
        <Input
          type="password"
          label="Oud wachtwoord"
          name="oldPassword"
          value={this.state.oldPassword}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
        <Input
          type="password"
          label="Nieuw wachtwoord"
          name="newPassword"
          value={this.state.newPassword}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />
        <Input
          type="password"
          label="Nieuw wachtwoord (herhalen)"
          name="newPasswordRepeat"
          value={this.state.newPasswordRepeat}
          onChange={this.handleChange}
          onKeyPress={this.handleKeyPress}
        />

        {this.state.message ? (
          <p className="error">{this.state.message}</p>
        ) : null}

        <Button onClick={this.changePassword}>Wijzig wachtwoord</Button>
      </div>
    );
  }
}

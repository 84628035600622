import React, { Component } from 'react';
import type from 'prop-types';

import './Button.css';
import { Link } from '../';

export default class Button extends Component {
  render() {
    const { iconLeft, iconRight, ...newProps } = this.props;

    const inner = (
      <span>
        {this.props.iconLeft ? (
          <i className={'icon-left icon ' + this.props.iconLeft} />
        ) : null}
        {this.props.children}
        {this.props.iconRight ? (
          <i className={'icon-right icon ' + this.props.iconRight} />
        ) : null}
      </span>
    );

    return (
      <div className="Button_container" {...newProps}>
        {this.props.link ? (
          <Link
            to={this.props.link || '/'}
            className="Button_link"
            target={this.props.target ? this.props.target : null}
            rel={this.props.target ? 'noopener noreferrer' : null}
          >
            {inner}
          </Link>
        ) : (
          <div className="Button_link">{inner}</div>
        )}
      </div>
    );
  }
}

Button.propTypes = {
  children: type.oneOfType([type.string, type.array]),
  onClick: type.func,
  link: type.oneOfType([type.string, type.object]),
  icon: type.string
};

import React, { Component } from 'react';
import type from 'prop-types';
import './H1.css';

export default class H1 extends Component {
  render() {
    return <span className="H1_content">{this.props.children}</span>;
  }
}

H1.propTypes = {
  children: type.string
};

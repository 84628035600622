import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Link, Spinner, Input } from '../../../General';
import { ChallengeReply } from '../../../Challenge';
import { graphQLFetch } from '../../../../helpers';
import './DashboardScreen.css';
import logo from '../../../../images/logo.svg';
import config from '../../../../config';
import queries from './queries';
import otherQueries from '../../Challenges/queries';

export default class CompanyDashboardScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: null,
      shouldRedirect: false,
      widgetOpen: false,
      token: null
    };

    this.logout = this.logout.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.addReplyReply = this.addReplyReply.bind(this);
  }

  componentDidMount() {
    this.setState({ isLoading: true });

    const token = localStorage.getItem('token');

    graphQLFetch(queries.getCompanyDataQuery(token))
      .then(json => {
        this.setState({ data: json.data.companyData, isLoading: false, token });
      })
      .catch(err => {
        localStorage.removeItem('token');
        this.setState({ isLoading: false });
      });
  }

  logout() {
    localStorage.removeItem('token');
    this.setState({ shouldRedirect: true });
  }

  handleClick(e) {
    e.target.select();
    document.execCommand('copy');
    const clipb = document.getElementById('copied-to-clipboard');
    if (clipb) {
      clipb.style.display = 'block';
    }
    // e.target.blur();
  }

  addReplyReply(challengeId, text, token) {
    if (token) {
      return graphQLFetch(
        otherQueries.addReplyReplyQueryByCompany(challengeId, text, token)
      )
        .then(replyReplyJson => {
          this.setState({ isLoading: true });

          return graphQLFetch(queries.getCompanyDataQuery(token)).then(json => {
            this.setState({
              data: json.data.companyData,
              isLoading: false
            });
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      console.log('error - no company token');
    }
  }

  render() {
    const { match } = this.props;
    const paramName = match.params.name;

    if (this.state.shouldRedirect)
      return <Redirect to={`/bedrijf/${paramName}`} />;

    if (this.state.isLoading)
      return (
        <div className="centered-container">
          <Spinner />
        </div>
      );

    if (!this.state.isLoading && !this.state.data) {
      // localStorage.removeItem('token');
      return (
        <div className="CompanyLoginScreen_ centered-container">
          <p>Er is iets misgegaan, gelieve opnieuw in te loggen</p>
          <Link to={`/bedrijf/${paramName}`}>Terug naar login</Link>
        </div>
      );
    }

    return (
      <div className="CompanyDashboardScreen_">
        <div className="CompanyDashboardScreen_header">
          <div className="CompanyDashboardScreen_header_logo">
            <Link to="/">
              <img src={logo} alt="Logo" />
            </Link>
          </div>
          Ingelogd als {this.state.data.company.name} &nbsp; &nbsp; &nbsp;
          <a onClick={this.logout}>Uitloggen</a>
        </div>
        <div className="container" style={{ textAlign: 'center' }}>
          <p>
            U bent ingelogd. Als u reageert op een idee, zal de naam van uw
            bedrijf naast de reactie verschijnen.
          </p>
          <br />
          📃{' '}
          <a
            className="CompanyDashboardScreen_widget-title"
            onClick={() =>
              this.setState({ widgetOpen: !this.state.widgetOpen })
            }
          >
            Maak widget aan{' '}
            {this.state.widgetOpen ? (
              <i className="icon ion-ios-arrow-down" />
            ) : (
              <i className="icon ion-ios-arrow-forward" />
            )}
          </a>
          {this.state.widgetOpen ? (
            <div className="CompanyDashboardScreen_widget">
              <p>Plaats deze code in jouw website</p>
              <div style={{ display: 'inline-block' }}>
                <Input
                  multiline
                  value={
                    '<iframe src="https://mijnidee.be/challenge/' +
                    this.state.data.challenges[0].id +
                    '/widget" width="400" height="550" frameborder="0" allowfullscreen ' +
                    'style="border:0;box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.25);border-radius: 20px;margin:20px 10px;"></iframe>'
                  }
                  readOnly
                  onClick={this.handleClick}
                />
                <p id="copied-to-clipboard">
                  <span role="img" aria-label="clipboard">
                    📋
                  </span>{' '}
                  Tekst naar klembord gekopieerd
                </p>
              </div>
            </div>
          ) : null}
          <br />
          <form method="POST" action={config.server + '/excel'}>
            <input type="hidden" name="token" value={this.state.token} />
            💾{' '}
            <input
              type="submit"
              value="Download Excel-rapport"
              className="CompanyDashboardScreen_widget-title"
              style={{ marginBottom: 10 }}
            />
          </form>
          <Link to={`/bedrijf/${paramName}/wachtwoord`}>
            Kies nieuw wachtwoord
          </Link>
          <br />
          <br />
          {this.state.data.challenges.map(challenge => {
            return (
              <div key={challenge.id}>
                <h2>Antwoorden op de challenge</h2>
                <div className="CompanyDashboardScreen_replies">
                  {challenge.challengeReplies.map(reply => {
                    return (
                      <ChallengeReply
                        key={reply.id}
                        reply={reply}
                        votedFor={JSON.parse(localStorage.getItem('votedFor'))}
                        onUpvote={() => {
                          this.upvoteReply(reply.id);
                        }}
                        onReply={text => {
                          this.addReplyReply(
                            reply.id,
                            text,
                            localStorage.getItem('token')
                          );
                        }}
                        newReplyReplyId={this.state.newReplyReplyId}
                        active
                        forAdmins
                      />
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

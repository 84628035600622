import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import type from 'prop-types';

import { ChallengeTitle } from '../../Challenge';
import { Input, Button, ContentHeader, Spinner, Link, H2 } from '../../General';
import config from '../../../config';
import { graphQLFetch, sendAnalytics } from '../../../helpers';
import queries from './queries';

import './AnswerScreen.css';

export default class AnswerScreen extends Component {
  constructor(props) {
    super(props);

    let descriptionShort = '';
    if (this.props.location.state)
      descriptionShort = this.props.location.state.descriptionShort;

    this.state = {
      challenge: null,
      descriptionShort,
      description: '',
      email: '',

      isLoading: false,
      attachmentIsLoading: false,
      photoIsLoading: false,
      isValid: true,
      tooLong: false,

      emailInvalid: false,
      descriptionShortInvalid: false,
      descriptionInvalid: false,

      validationMessages: [],
      attachment: null,
      attachments: [],
      photo: {},
      photoURL: null,

      shouldRedirect: false,

      agreeNotifications: true,
      agreePrivacy: false,
      agreePublish: false
    };

    this.submitForm = this.submitForm.bind(this);
    this.addAttachment = this.addAttachment.bind(this);
    this.addPhoto = this.addPhoto.bind(this);

    this.handleDescriptionShort = this.handleDescriptionShort.bind(this);
    this.handleDescription = this.handleDescription.bind(this);
    this.handleEmail = this.handleEmail.bind(this);

    this.handleCbNotifications = this.handleCbNotifications.bind(this);
    this.handleCbPrivacy = this.handleCbPrivacy.bind(this);
    this.handleCbPublish = this.handleCbPublish.bind(this);
  }

  componentDidMount() {
    const state = this.props.location.state;

    // Location was passed from previous screen
    if (state && state.challenge) {
      const challenge = state.challenge;
      if (challenge) {
        return this.setState({ challenge });
      }
    }

    // Otherwise, fetch from backend
    this.fetchChallenge();

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  handleDescriptionShort(e) {
    if (e.target.value.length > 128) {
      this.setState({
        descriptionShort: e.target.value,
        descriptionShortInvalid: false,
        tooLong: true
      });
    } else {
      this.setState({
        descriptionShort: e.target.value,
        descriptionShortInvalid: false,
        tooLong: false
      });
    }
  }

  handleDescription(e) {
    this.setState({ description: e.target.value, descriptionInvalid: false });
  }

  handleEmail(e) {
    this.setState({ email: e.target.value, emailInvalid: false });
  }

  fetchChallenge() {
    const { match } = this.props;
    const paramId = parseInt(match.params.id, 10);
    if (isNaN(paramId)) return <Redirect to="/" />;

    graphQLFetch(queries.getChallengeQuery(paramId))
      .then(json => {
        this.setState({ challenge: json.data.challenge });
      })
      .catch(err => {
        console.log(err);
      });
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validateForm() {
    const emptyFields = [];
    const validationMessages = [];
    let isValid = true;
    let hasEmptyFields = false;
    let descriptionInvalid = false;
    let descriptionShortInvalid = false;
    let emailInvalid = false;

    if (!this.state.descriptionShort || this.state.descriptionShort === '') {
      emptyFields.push('korte beschrijving');
      hasEmptyFields = true;
      descriptionShortInvalid = true;
    }
    if (!this.state.email || this.state.email === '') {
      emptyFields.push('e-mail');
      hasEmptyFields = true;
      emailInvalid = true;
    }
    if (hasEmptyFields) {
      isValid = false;
      let msg = 'Verplichte velden: ';
      emptyFields.forEach((s, i) => {
        msg += s;
        if (i !== emptyFields.length - 1) msg += ', ';
      });
      validationMessages.push(msg);
    }
    if (this.state.descriptionShort.length > 128) {
      isValid = false;
      descriptionShortInvalid = true;
      validationMessages.push('Korte beschrijving is max. 128 karakters');
    }
    if (!this.validateEmail(this.state.email)) {
      isValid = false;
      emailInvalid = true;
      validationMessages.push('Ongeldig e-mailadres');
    }
    if (!this.state.agreePrivacy) {
      isValid = false;
      validationMessages.push(
        'Om een idee te publiceren dient u akkoord te gaan met onze voorwaarden'
      );
    }
    if (!this.state.agreePublish) {
      isValid = false;
      validationMessages.push(
        'Om een idee te publiceren dient u akkoord te gaan met het publiek plaatsen ervan'
      );
    }

    return {
      isValid,
      validationMessages,
      descriptionShortInvalid,
      descriptionInvalid,
      emailInvalid
    };
  }

  submitForm() {
    this.setState({
      isLoading: true,
      isValid: true,
      descriptionShortInvalid: false,
      descriptionInvalid: false,
      emailInvalid: false
    });

    // Validate form
    const validation = this.validateForm();
    // console.log(validation.isValid);

    if (!validation.isValid) {
      this.setState({
        isLoading: false,
        isValid: false,
        validationMessages: validation.validationMessages,
        descriptionShortInvalid: validation.descriptionShortInvalid,
        descriptionInvalid: validation.descriptionInvalid,
        emailInvalid: validation.emailInvalid
      });
      return;
    }

    // Parse JS array to graphQL schema language
    let formattedAttachments = '';
    this.state.attachments.forEach(attachment => {
      formattedAttachments +=
        '{ id: ' + attachment.id + ', shortId: "' + attachment.shortid + '" } ';
    });

    console.log(queries.addChallengeQuery(this.state, formattedAttachments));

    graphQLFetch(queries.addChallengeQuery(this.state, formattedAttachments))
      .then(json => {
        if (json.errors) {
          json.errors.forEach(error => console.error(error));
          throw Error(json.errors);
        }
        this.setState({
          challenge: json.data.addChallengeReply,
          isLoading: false,
          shouldRedirect: true
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  }

  addAttachment(e) {
    this.setState({ attachmentIsLoading: true });

    const files = e.target.files;

    if (!files) {
      this.setState({ attachmentIsLoading: false });
      return;
    }

    // Fill up form data with attachments
    const formData = new FormData();
    const filesArray = Array.from(files);
    filesArray.forEach(file => {
      formData.append('file', file);
    });

    return fetch(`${config.server}/upload`, {
      method: 'POST',
      body: formData
    })
      .then(res => {
        res.json().then(json => {
          const attachments = [...this.state.attachments, ...json.data];
          this.setState({ attachmentIsLoading: false, attachments });
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ attachmentIsLoading: false });
      });
  }

  removeAttachment(attachment) {
    const filteredAttachments = this.state.attachments.filter(att => {
      return att.id !== attachment.id;
    });
    this.setState({ attachments: filteredAttachments });
  }

  addPhoto(e) {
    this.setState({ photoIsLoading: true });

    const file = e.target.files[0];

    if (!file) {
      this.setState({ photoIsLoading: false });
      return;
    }

    // Create formdata
    const url = `${config.server}/upload`;
    const formData = new FormData();
    formData.append('file', file);

    return fetch(url, {
      method: 'POST',
      body: formData
    })
      .then(res => {
        res.json().then(json => {
          const reader = new FileReader(); // Get filename
          reader.readAsDataURL(file);
          reader.onloadend = () => {
            this.setState({
              photoIsLoading: false,
              photo: json.data[0],
              photoURL: [reader.result]
            });
          };
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ photoIsLoading: false });
      });
  }

  handleCbNotifications() {
    this.setState({ agreeNotifications: !this.state.agreeNotifications });
  }

  handleCbPrivacy() {
    this.setState({ agreePrivacy: !this.state.agreePrivacy });
  }

  handleCbPublish() {
    this.setState({ agreePublish: !this.state.agreePublish });
  }

  render() {
    const challenge = this.state.challenge;

    if (!challenge || this.state.isLoading)
      return (
        <div className="centered-container">
          <Spinner />
        </div>
      );
    if (this.state.shouldRedirect)
      return (
        <Redirect
          to={{
            pathname: `/survey`,
            state: { refetch: true }
          }}
        />
      );

    return (
      <div className="container AnswerScreen_container">
        <ChallengeTitle>{challenge.title}</ChallengeTitle>
        <Link to={`/challenge/${challenge.id}`}>
          <i className="icon ion-ios-arrow-back" /> Terug naar challenge
        </Link>

        <ContentHeader>Formuleer een antwoord</ContentHeader>

        <Input
          label="Korte beschrijving"
          required
          multiline
          placeholder="Beschrijf jouw antwoord beknopt..."
          value={this.state.descriptionShort}
          onChange={this.handleDescriptionShort}
          invalid={this.state.descriptionShortInvalid}
        />
        {this.state.tooLong ? (
          <p className="error">
            {this.state.descriptionShort.length}
            /128 karakters
            <br />
          </p>
        ) : null}
        <Input
          label="Uitgebreide beschrijving"
          multiline
          placeholder="Licht je antwoord uitgebreid toe..."
          value={this.state.description}
          onChange={this.handleDescription}
          invalid={this.state.descriptionInvalid}
        />
        <div>
          <H2>Voeg een foto toe</H2>

          <div className="AnswerScreen_upload-photo">
            {this.state.photo && Object.keys(this.state.photo).length > 0 ? (
              <img
                src={this.state.photoURL}
                className="AnswerScreen_upload-photo_photo"
                alt={`Geselecteerde foto`}
              />
            ) : null}

            {this.state.photoIsLoading ? (
              <div className="AnswerScreen_spinner">
                <p>Aan het uploaden...</p>
                <Spinner />
              </div>
            ) : null}

            <input
              type="file"
              name="photo"
              onChange={this.addPhoto}
              accept="image/*"
            />
          </div>

          <H2>Voeg bijlagen toe</H2>

          <div className="AnswerScreen_attachments">
            {this.state.attachments && this.state.attachments.length > 0 ? (
              <div className="AnswerScreen_attachments_items">
                {this.state.attachments.map(attachment => {
                  return (
                    <div key={attachment.id}>
                      {attachment.originalname} &nbsp;
                      <a
                        onClick={this.removeAttachment.bind(this, attachment)}
                        className="AnswerScreen_attachments_items_delete"
                      >
                        <i className="icon ion-md-trash" />
                      </a>
                    </div>
                  );
                })}
              </div>
            ) : null}

            {this.state.attachmentIsLoading ? (
              <div className="AnswerScreen_spinner">
                <p>Aan het uploaden...</p>
                <Spinner />
              </div>
            ) : null}

            <input
              type="file"
              name="file"
              multiple={true}
              onChange={this.addAttachment}
            />
          </div>
        </div>

        <Input
          label="E-mail"
          required
          placeholder="mijn.naam@email.com"
          value={this.state.email}
          onChange={this.handleEmail}
          type="email"
          invalid={this.state.emailInvalid}
        />

        <label className="AnswerScreen_checkbox">
          <input
            type="checkbox"
            checked={this.state.agreeNotifications}
            onChange={this.handleCbNotifications}
          />{' '}
          Ik wil graag op de hoogte worden gehouden van verdere reacties.
        </label>
        <label className="AnswerScreen_checkbox">
          <input
            type="checkbox"
            checked={this.state.agreePrivacy}
            onChange={this.handleCbPrivacy}
          />{' '}
          Ik ga akkoord met de{' '}
          <Link to="/privacy" target="_blank" rel="noopener noreferrer">
            algemene voorwaarden
          </Link>
          .
        </label>
        <label className="AnswerScreen_checkbox">
          <input
            type="checkbox"
            checked={this.state.agreePublish}
            onChange={this.handleCbPublish}
          />{' '}
          Ik geef toestemming dat mijn idee wordt gepubliceerd op de website en
          geef het bedrijf toelating om mij te contacteren om mijn idee toe te
          lichten.
        </label>

        {!this.state.isValid ? (
          <div className="AnswerScreen_errors">
            <h2 className="error">
              <strong>Antwoord niet verzonden</strong>
            </h2>
            <ul>
              {this.state.validationMessages.map((msg, i) => (
                <li key={i}>{msg}</li>
              ))}
            </ul>
          </div>
        ) : null}

        <Button onClick={this.submitForm}>Beantwoord aan challenge</Button>
      </div>
    );
  }
}

AnswerScreen.propTypes = {
  match: type.object,
  location: type.object
};

import React, { Component } from 'react';

import { Query } from 'react-apollo';
import gql from 'graphql-tag';

import './HomeScreen.css';

import { Header, Card, CardPlaceholder, InspireForm } from '../../Home';
import { ContentHeader, Footer, Button } from '../../General';
import { sendAnalytics } from '../../../helpers';
import config from '../../../config';

// import './Navigator.css';

const homeImg = require('../../../images/confidas-home.png');

export default class HomeScreen extends Component {
  constructor(props) {
    super(props);

    this.submitForm = this.submitForm.bind(this);
  }

  scrollToForm() {
    const form = document.getElementById('form');
    if (form) {
      form.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  scrollToChallenges() {
    const challenges = document.getElementById('challenges');
    if (challenges) {
      challenges.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  submitForm() {
    document.getElementById('form').submit();
  }

  componentDidMount() {
    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  render() {
    return (
      <div>
        <Header onScrollArrowClick={this.scrollToChallenges} />
        <div className="container">
          <ContentHeader>Klanten helpen jou innoveren! We helpen jou in 5 stappen op weg.</ContentHeader>
            
          <div className="steps-container">
            <div>
              <div className="quotecontainerfkcss">
                <span className="open">&ldquo;</span>
                <p className="quote">
                  De resultaten zijn gelijkwaardig
                  <br />
                  aan die van een
                  <br/>
                  beperkt marktonderzoek
                  <br/>
                </p>
                <span className="close">&rdquo;</span>
              </div>
              <img
                src={homeImg}
                className="homeimg"
                alt="Productfoto Confidas."
              />
              <p className="after-text">
                De challenge van Confidas kreeg op 2 dagen tijd meer dan 10
                antwoorden binnen
              </p>
            </div>
            <div>
              <ul>
                <li>
                  <h3>1. Een uitdaging voor jouw bedrijf bepalen</h3>
                  <p>
                    Samen met jou en eventuele medewerkers gaan we op zoek naar
                    een verandering in jouw bedrijf waar de mening van klanten bij
                    kan helpen.
                  </p>
                </li>
                <li>
                  <h3>2. Een challenge opstellen op maat van jouw klanten</h3>
                  <p>
                    We vormen samen de uitdaging om tot een wervende vraag met
                    eenvoudige omschrijving op maat van jouw klanten. <br />
                    Wij plaatsen deze online op mijnidee.be.
                  </p>
                </li>
                <li>
                  <h3>3. De challenge verspreiden naar de juiste doelgroep</h3>
                  <p>
                    Jij verspreidt de challenge gericht naar klanten en
                    kennissen. <br />
                    Wij helpen jou om de juiste doelgroep te selecteren.
                  </p>
                </li>
                <li>
                  <h3>4. De ideeën en reacties opvolgen</h3>
                  <p>
                    Wij volgen de ideeën op. Je krijgt automatisch een mailtje
                    als er een nieuw idee is. <br />
                    Op ongeveer 3 dagen tijd heb je de meeste ideeën binnen.
                  </p>
                </li>
                <li>
                  <h3>5. De ideeën analyseren en interpreteren</h3>
                  <p>
                    We bespreken samen met jou de resultaten. <br />
                    De ideeën worden met een open geest geïnterpreteerd.
                  </p>
                </li>
              </ul>
              <Button
                iconLeft="ion-md-download"
                onClick={() => {
                  window.location = config.server + '/stappenplan';
                  // fetch(config.server + '/stappenplan')
                  //   .then(res => {
                  //     console.log(res);
                  //   })
                  //   .catch(err => {
                  //     console.log(err);
                  //   });
                }}
              >
                Download het stappenplan
              </Button>
            </div>
          </div>
        </div>


        <div className="container accent">

          <ContentHeader>
            Zelf op zoek naar klantgedreven innovatie?
          </ContentHeader>

          <InspireForm submitForm={this.submitForm} />
        </div>

        <div className="container">

          <Query query={getActiveChallengesQuery()}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div className="HomeScreen_cardcontainer">
                    <CardPlaceholder />
                    <CardPlaceholder />
                  </div>
                );
              if (error) {
                console.log(error);
                return (
                  <div>
                    <p>
                      Er is iets misgegaan{' '}
                      <span role="img" aria-label="Blank emoji">
                        &#x1F610;
                      </span>
                      <br />
                    </p>
                    <pre>{error.message}</pre>
                  </div>
                );
              }
              if (data.activeChallenges.length > 0) {
                return (
                  <div>
                    <ContentHeader id="challenges">
                      Ontdek de challenges
                    </ContentHeader>

                    <div className="HomeScreen_cardcontainer">
                      {data.activeChallenges.map(challenge => {
                        return (
                          <Card key={challenge.id} challenge={challenge} />
                        );
                      })}
                    </div>
                  </div>
                );
              } else return null;
            }}
          </Query>

          {/* <a onClick={this.scrollToForm}>Lanceer zelf een challenge voor uw bedrijf!</a> */}

          <Query query={getInactiveChallengesQuery()}>
            {({ loading, error, data }) => {
              if (loading)
                return (
                  <div className="HomeScreen_cardcontainer">
                    <CardPlaceholder />
                    <CardPlaceholder />
                  </div>
                );
              if (error) {
                console.log(error);
                return (
                  <div>
                    <p>
                      Er is iets misgegaan{' '}
                      <span role="img" aria-label="Blank emoji">
                        &#x1F610;
                      </span>
                      <br />
                    </p>
                    <pre>{error.message}</pre>
                  </div>
                );
              }
              if (data.inactiveChallenges.length > 0) {
                return (
                  <div>
                    <ContentHeader id="inactivechallenges">
                      Afgelopen challenges
                    </ContentHeader>
                    <div className="HomeScreen_cardcontainer">
                      {data.inactiveChallenges.map(challenge => {
                        return (
                          <Card key={challenge.id} challenge={challenge} />
                        );
                      })}
                    </div>
                  </div>
                );
              } else return null;
            }}
          </Query>
        </div>

        <Footer />
      </div>
    );
  }
}

const getActiveChallengesQuery = () => {
  return gql`
    {
      activeChallenges {
        id
        title
        active
        description
        replyCount
        company {
          id
          name
          region
          photoShortId
        }
      }
    }
  `;
};

const getInactiveChallengesQuery = () => {
  return gql`
    {
      inactiveChallenges {
        id
        title
        active
        description
        replyCount
        company {
          id
          name
          region
          photoShortId
        }
      }
    }
  `;
};

/*** GraphQL Queries ***/

const getChallengeQuery = id => {
  return {
    query: `
      {
        challenge(id: ${id}) {
          id
          title
          description
          replyCount

          company{
            id
            name
            description
            region
            siteUrl
          }

          challengeReplies {
            id
            descriptionShort
            description
            upvotes
            photoShortId      
            uploads {
              shortid
              originalname
            }

            replyReplies{
              id
              description
              formattedDate
            }
          }
        }
      }
    `
  };
};

const addReplyReplyQueryByCompany = (id, text, token) => {
  return {
    query: `
      mutation {
        addReplyReply(challengeReplyId: ${id}, description: """ ${text} """, token: "${token}") {
          id
        }
      }
    `
  };
};

const addReplyReplyQuery = (id, text) => {
  return {
    query: `
      mutation {
        addReplyReply(challengeReplyId: ${id}, description: """ ${text} """) {
          id
        }
      }
    `
  };
};

const upvoteReplyQuery = id => {
  return {
    query: `
      mutation {
        upvoteReply(replyId: ${id}) {
          id
        }
      }
    `
  };
};

const getChallengeRepliesQuery = challengeId => {
  return {
    query: `
      {
        challengeReplies(challengeId: ${challengeId}) {
          id
          descriptionShort
          description
          upvotes
          photoShortId      
          uploads {
            shortid
            originalname
          }

          replyReplies{
            id
            description
            formattedDate

            company{
              id
              name
              description
              region
              siteUrl
            }
          }
        }
      }
    `
  };
};

export default {
  upvoteReplyQuery,
  addReplyReplyQueryByCompany,
  addReplyReplyQuery,
  getChallengeQuery,
  getChallengeRepliesQuery
};

import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
  HomeScreen,
  AnswerScreen,
  NotFoundScreen,
  EmailSentScreen,
  Company,
  AtelierWatt,
  Coach,
  Confidas1,
  VDCalseyde,
  RAF,
  Zonen09,
  PrivacyScreen,
  CookieScreen,
  SurveyScreen
} from './index';

class Navigator extends Component {
  render() {
    return (
      <div className="Navigator_container">
        <Switch>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/challenge/1" component={RAF} />
          <Route exact path="/challenge/2" component={Zonen09} />
          <Route exact path="/challenge/3" component={AtelierWatt} />
          <Route exact path="/challenge/4" component={Confidas1} />
          <Route exact path="/challenge/5" component={Coach} />
          <Route exact path="/challenge/6" component={VDCalseyde} />
          <Route
            exact
            path="/challenge/:id/antwoord"
            component={AnswerScreen}
          />
          <Route
            exact
            path="/challenge/:id/widget"
            component={Company.Widget}
          />
          <Route exact path="/emailsent" component={EmailSentScreen} />
          <Route exact path="/bedrijf/:name" component={Company.LoginScreen} />
          <Route
            exact
            path="/bedrijf/:name/dashboard"
            component={Company.DashboardScreen}
          />
          <Route
            exact
            path="/bedrijf/:name/wachtwoord"
            component={Company.PasswordScreen}
          />
          <Route exact path="/privacy" component={PrivacyScreen} />
          <Route exact path="/cookies" component={CookieScreen} />
          <Route exact path="/survey" component={SurveyScreen} />
          <Route component={NotFoundScreen} />
        </Switch>
      </div>
    );
  }
}

export default Navigator;

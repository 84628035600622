import React, { Component } from 'react';

import { Footer, Button, Link, ContentHeader } from '../../General';
import { ChallengeReply } from '../../Challenge';
import { graphQLFetch, drawBullet, sendAnalytics } from '../../../helpers';

import './ChallengeScreen.css';
import logo from '../../../images/logo.svg';
import queries from './queries';

import imgLeft from '../../../images/sharon.png';
import imgRight from '../../../images/zonen09_2.jpg';

class Zonen09 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newReplyReplyId: null,
      descriptionShort: '',
      showMore: false,
      challengeReplies: null
    };

    this.upvoteReply = this.upvoteReply.bind(this);
    this.addReplyReply = this.addReplyReply.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.getChallengeReplies = this.getChallengeReplies.bind(this);
  }

  componentDidMount() {
    this.getChallengeReplies(2);

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    // This element only exists after fetching data, that's why we check in componentDidUpdate
    if (window.location.hash === '#antwoorden') {
      const antwoorden = document.getElementById('antwoorden');
      if (antwoorden) {
        antwoorden.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'instant'
        });
      }
    }

    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  scrollToReplies() {
    const antwoorden = document.getElementById('antwoorden');
    if (antwoorden) {
      antwoorden.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  scrollToTop() {
    //    window.scroll({ top: 0, behavior: 'smooth' });

    const top = document.getElementById('root');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  upvoteReply(id) {
    let votedFor = JSON.parse(localStorage.getItem('votedFor'));

    if (!votedFor) votedFor = [];
    if (votedFor.indexOf(id) !== -1) {
      return;
    }

    votedFor.push(id);
    localStorage.setItem('votedFor', JSON.stringify(votedFor));

    return graphQLFetch(queries.upvoteReplyQuery(id))
      .then(() => {
        this.getChallengeReplies(2);
      })
      .catch(err => {
        console.log(err);
      });
  }

  addReplyReply(challengeId, text) {
    return graphQLFetch(queries.addReplyReplyQuery(challengeId, text))
      .then(replyReplyJson => {
        this.getChallengeReplies(2, replyReplyJson.data.addReplyReply.id);
      })
      .catch(err => {
        console.log(err);
      });
  }

  getChallengeReplies(challengeId, newReplyReplyId = null) {
    graphQLFetch(queries.getChallengeRepliesQuery(challengeId)).then(json => {
      if (!json.data.challengeReplies) {
        throw Error('Geen antwoorden gevonden');
      }
      this.setState({
        challengeReplies: json.data.challengeReplies,
        newReplyReplyId
      });
    });
  }

  handleDescriptionChange(e) {
    this.setState({ descriptionShort: e.target.value });
  }

  render() {
    const challengeReplies = this.state.challengeReplies;

    if (this.state.error) return <p>Er is iets misgegaan :(</p>;

    return (
      <div>
        <div className="ChallengeScreen_ container">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="ChallengeScreen_header_logo"
            />
          </Link>

          <div className="ChallengeScreen_header">
            <p className="ChallengeScreen_header_title inactive_title">
              Hoe kan ik ook jouw patronenhacks aan mijn klanten aanbieden?
            </p>

            <ChallengeReply
              key={10}
              reply={{
                id: 10,
                descriptionShort:
                  'Een Facebook groep maken en daar mensen nadrukkelijk uitnodigen hun hacks te delen. ',
                description: '',
                upvotes: 4,
                photoShortId: null,
                uploads: [],
                replyReplies: []
              }}
              active={false}
              final={true}
              votedFor={JSON.parse(localStorage.getItem('votedFor'))}
            />

            {/* <Input
              multiline
              placeholder="Mijn idee of mening hierover is..."
              onChange={this.handleDescriptionChange}
            />

            <Button
              iconRight="ion-ios-arrow-forward"
              link={{
                pathname: `/challenge/2/antwoord`,
                state: {
                  descriptionShort: this.state.descriptionShort
                }
              }}
            >
              Verstuur je idee
            </Button> */}

            {challengeReplies && challengeReplies.length === 0 ? null : (
              <a onClick={this.scrollToReplies}>
                Ontdek alle ingestuurde ideeën
              </a>
            )}
          </div>

          <div className="ChallengeScreen_two-cols">
            <div className="ChallengeScreen_col-left">
              <div className="ChallengeScreen_col-left_img">
                <img
                  src={imgLeft}
                  alt={`Zonen 09 - decoratief.`}
                  className=""
                />
              </div>
            </div>
            <div>
              <ContentHeader>
                Waarom wou Sharon van Zonen 09 dit weten?
              </ContentHeader>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()} Klanten maken creatieve{' '}
                <strong>variaties op bestaande naaipatronen</strong>.
              </p>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()} Ik wil deze variaties zien en eventueel{' '}
                <strong>opnemen in mijn aanbod</strong> of handleiding
              </p>
              <p className="ChallengeScreen_vcenter">
                {drawBullet()} Ik wil deze variaties{' '}
                <strong>zichtbaar maken naar andere klanten</strong>
              </p>

              <p>
                Zonen 09 wil de creatieve resultaten van haar klanten (bijv.
                patronenhacks) <strong>omzetten in een bruikbaar aanbod</strong>{' '}
                (bijv. how-to handleidingen) in haar webshop/op haar
                website/social media.
              </p>
              <p>
                Rekening houdend met het feit dat Zonen 09 een eenmanszaak is en
                tijd en budget beperkt zijn, op welke manier zou dit aangepakt
                kunnen worden?
              </p>

              {/* <p className="ChallengeScreen_idea-link">
                <a onClick={this.scrollToTop}>Ik heb een idee!</a>
              </p> */}
            </div>
          </div>

          {challengeReplies && challengeReplies.length > 0 ? (
            <ContentHeader id="antwoorden">
              Anderen dachten er zo over
            </ContentHeader>
          ) : null}
          <div className="ChallengeScreen_replies">
            {challengeReplies &&
              challengeReplies.map((reply, i) => {
                return this.state.showMore || i < 3 ? (
                  <ChallengeReply
                    key={reply.id}
                    reply={reply}
                    active={false}
                    votedFor={JSON.parse(localStorage.getItem('votedFor'))}
                    onUpvote={() => {
                      this.upvoteReply(reply.id);
                    }}
                    onReply={text => {
                      this.addReplyReply(reply.id, text);
                    }}
                    newReplyReplyId={this.state.newReplyReplyId}
                  />
                ) : null;
              })}
          </div>

          {!this.state.showMore &&
          challengeReplies &&
          challengeReplies.length > 0 &&
          challengeReplies.length > 3 ? (
            <Button
              onClick={() => this.setState({ showMore: true })}
              iconRight="ion-ios-arrow-down"
            >
              Toon alle ideeën
            </Button>
          ) : null}

          <ContentHeader>Over Zonen 09</ContentHeader>
          <div className="ChallengeScreen_two-cols">
            <div>
              <p>
                Zonen 09 wil je helpen maken wat je zelf nergens vindt. En dat
                met <strong>stijlvolle ontwerpen</strong> op biokatoen en{' '}
                <strong>knappe naaipatronen</strong>
                vergezeld van de meest uitgebreide en duidelijke uitleg op de
                markt.
              </p>

              <p>
                We willen je helpen bij het onderhouden van een{' '}
                <strong>duurzame levensstijl</strong> en maken het jou daarom zo
                gemakkelijk mogelijk om zelf verantwoorde, modieuze kledij te
                naaien. <br />
                Daarom printen we onze naaipatronen op FSC gecertificeerd papier
                en wanneer mogelijk op gerecycleerd papier. <br />
                Het is ook dé reden van onze keuze voor biokatoen bij onze eigen
                stoffenlijn.
              </p>
              <p>
                Onze geprinte en jacquard stoffen bestaan uit{' '}
                <strong>100% GOTS gecertificeerd biokatoen</strong>. <br />
                Onze gekleurde collecties bestaan uit een combinatie van
                biokatoen en kunstvezels.
              </p>
              <p className="ChallengeScreen_light-text">
                <br />
                <i className="icon ion-ios-globe" /> &nbsp;Regio: Gent
                <br />
                <i className="icon ion-ios-link" /> &nbsp;
                <a
                  href="https://zonen09.be"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  zonen09.be
                </a>
              </p>
            </div>
            <div className="ChallengeScreen_col-right">
              <img
                src={imgRight}
                alt={`Decoratieve foto Zonen 09.`}
                className="ChallengeScreen_col-right_img-clipped"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default Zonen09;

import React, { Component } from 'react';
import type from 'prop-types';
import './Placeholder.css';

export default class Card extends Component {
  render() {
    if (this.props.image) {
      return <p style={{ height: 200 }}> </p>;
    }
    return (
      <span
        className="Placeholder_container"
        style={{ width: 250 * Math.random() }}
        {...this.props}
      />
    );
  }
}

Card.propTypes = {
  image: type.bool
};

import React, { Component } from 'react';
import type from 'prop-types';
import { Input, Button } from '../../General';
import config from '../../../config';
import './InspireForm.css';

export default class InspireForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      comment: '',
      company: '',
      email: ''
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    return (
      <div className="InspireForm_">
        <form action={`${config.server}/sendmail`} method="POST" id="form">
          <div className="InspireForm_intro-text">
            <p className="InspireForm_left">
              We leven in tijden van snelle veranderingen. <br />
              Digitalisering grijpt in op alle aspecten van jouw bedrijf. Soms
              voelt dat bedreigend, maar digitalisering creëert ook kansen om te
              innoveren.
              <br />
              <br />
              <strong>
                Uit onderzoek van de Odisee Hogeschool blijkt dat incrementele
                innovatie binnen het bereik van elke KMO ligt.
              </strong>{' '}
              Ook jij hebt ongetwijfeld ideeën over productvernieuwing, nieuwe
              markten of een andere dienstverlening. Bij wie toets jij deze
              ideeën af?
              <br />
              Dit platform biedt jou gratis online ideeën van door jou
              geselecteerde klanten!
              <br />
              <br />
              Contacteer Wim & Wouter via het formulier. Wij stellen samen met
              jou de challenge op!
            </p>
          </div>

          <Input
            label="Naam van jouw bedrijf"
            placeholder=""
            name="company"
            value={this.state.txtCompany}
            onChange={this.handleChange}
          />
          <Input
            label="E-mailadres waarop we jou kunnen contacteren"
            placeholder=""
            name="email"
            value={this.state.txtEmail}
            onChange={this.handleChange}
          />
          <Input
            label="Heb jij al een challenge in gedachten of heb je nog vragen?"
            placeholder=""
            multiline
            name="comment"
            value={this.state.txtComment}
            onChange={this.handleChange}
          />

          <div className="InspireForm_button-container">
            <Button
              onClick={this.props.submitForm}
              iconRight="ion-ios-arrow-forward"
            >
              Versturen
            </Button>
          </div>
        </form>
      </div>
    );
  }
}

InspireForm.propTypes = {
  submitForm: type.func
};

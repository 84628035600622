import React, { Component } from 'react';
import type from 'prop-types';
import './ChallengeReply.css';
import { Button } from '../../General';
import { ChallengeExtended } from '../';
import config from '../../../config';

export default class ChallengeReply extends Component {
  constructor(props) {
    super(props);

    this.state = {
      extendedId: false
    };
  }

  render() {
    const reply = this.props.reply;

    return (
      <div
        id={`challenge-reply-${reply.id}`}
        className={`ChallengeReply_ ${
          this.props.final ? 'ChallengeReply_final' : ''
        }`}
      >
        <p className="ChallengeReply_description_short">
          <a onClick={() => this.setState({ extended: true })}>
            {reply.descriptionShort}
          </a>
        </p>
        {reply.photoShortId ? (
          <img
            src={`${config.server}/uploads/${reply.photoShortId}`}
            className="ChallengeReply_photo"
            alt={`Foto bij antwoord "${reply.title}"`}
            style={{
              margin: '0 auto',
              display: 'block',
              maxHeight: 150,
              objectFit: 'contain'
            }}
          />
        ) : null}
        {/* <p>{reply.description}</p> */}
        {this.props.forAdmins ? (
          <div>
            <p>
              {reply.email}
              <br />
              {reply.formattedDate}
            </p>
          </div>
        ) : null}
        <div className="ChallengeReply_stats">
          <p>
            <i className="icon ion-ios-people" /> &nbsp;
            {reply.upvotes}{' '}
            {this.props.active
              ? reply.upvotes === 1
                ? 'iemand denkt'
                : 'denken'
              : reply.upvotes === 1
                ? 'iemand dacht'
                : 'dachten'}{' '}
            er ook zo over &nbsp;
            <br />
            <i className="icon ion-ios-chatbubbles" /> &nbsp;
            <a onClick={() => this.setState({ extended: true })}>
              {`${reply.replyReplies.length} reactie`}
              {reply.replyReplies.length !== 1 ? 's' : ''}
            </a>
          </p>
        </div>

        {this.props.active ? (
          <div className="ChallengeReply_button-container">
            {!this.props.forAdmins &&
            (!this.props.votedFor ||
              (this.props.votedFor &&
                this.props.votedFor.indexOf(reply.id) === -1)) ? (
              <Button
                onClick={this.props.onUpvote}
                iconLeft="ion-ios-thumbs-up"
              >
                Zo denk ik er ook over
              </Button>
            ) : null}

            {this.props.active ? (
              // !this.props.forAdmins ? (
              <p>
                Niet helemaal mee eens?
                <br />
                <a onClick={() => this.setState({ extended: true })}>
                  Reageer op dit idee
                </a>
              </p>
            ) : // ) : null
            null}
          </div>
        ) : null}

        {this.state.extended ? (
          <div
            className="ChallengeReply_overlay"
            onClick={() => this.setState({ extended: false })}
          >
            <ChallengeExtended
              onClose={() => this.setState({ extended: false })}
              onReply={this.props.onReply}
              newReplyReplyId={this.props.newReplyReplyId}
              reply={reply}
              active={this.props.active}
              forAdmins={this.props.forAdmins}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ChallengeReply.propTypes = {
  reply: type.shape({
    id: type.number,
    title: type.string,
    description: type.string,
    upvotes: type.number,
    photoShortId: type.string,
    attachments: type.arrayOf(
      type.shape({
        shortid: type.string,
        originalname: type.string
      })
    ),
    replyReplies: type.arrayOf(
      type.shape({
        id: type.number,
        description: type.string,
        formattedDate: type.string
      })
    )
  }).isRequired,
  forAdmins: type.bool,
  onUpvote: type.func,
  onReply: type.func,
  votedFor: type.arrayOf(type.number)
};

import React, { Component } from 'react';
import type from 'prop-types';
import './ContentHeader.css';

export default class ContentHeader extends Component {
  render() {
    return (
      <div className="ContentHeader_container" {...this.props}>
        {this.props.children}
      </div>
    );
  }
}

ContentHeader.propTypes = {
  children: type.string
}
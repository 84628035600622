import React, { Component } from 'react';
import type from 'prop-types';
import './Card.css';

import { Button, Link } from '../../General';

import imgRaf1 from '../../../images/raf_2.png';
import imgZonen1 from '../../../images/sharon_front.jpg';
import imgWatt from '../../../images/challenges/marie.jpg';
import imgConfidas1 from '../../../images/challenges/confidas_3.png';
import imgVDCalseyde from '../../../images/challenges/vdcalseyde.jpg';
import img3Coach from '../../../images/challenges/3coach_circleframe.png';
import imgNewChallenge5 from '../../../images/newchallenge5/nophoto.png';

export default class Card extends Component {
  render() {
    const challenge = this.props.challenge;

    let cardImg = null;
    if (challenge.id === 1) cardImg = imgRaf1;
    else if (challenge.id === 2) cardImg = imgZonen1;
    switch (challenge.id) {
      case 1:
        cardImg = imgRaf1;
        break;
      case 2:
        cardImg = imgZonen1;
        break;
      case 3:
        cardImg = imgWatt;
        break;
      case 4:
        cardImg = imgConfidas1;
        break;
      case 5:
        cardImg = img3Coach;
        break;
      case 6:
        cardImg = imgVDCalseyde;
        break;
      case 7:
        cardImg = imgNewChallenge5;
        break;
      default:
        cardImg = imgRaf1;
    }

    return (
      <div className="Card_container">
        <p className="Card_head">
          <strong>{challenge.company.name}</strong> uit&nbsp;&nbsp;
          <span>
            <i className="icon ion-ios-pin" style={{ marginRight: 2 }} />

            <strong style={{ wordWrap: 'normal' }}>
              {challenge.company.region}
            </strong>
          </span>
          <br /> {challenge.active ? 'wil weten...' : 'wou weten...'}
        </p>

        <p className="Card_title">
          <Link to={'/challenge/' + challenge.id}>{challenge.title}</Link>
        </p>

        <img src={cardImg} className="Card_image" alt="Foto" />

        <p className="Card_reply-count">
          <i className="icon ion-ios-create" />{' '}
          {challenge.active ? 'Reeds' : ''}
          &nbsp;
          {challenge.replyCount > 0 ? (
            <Link to={`/challenge/${challenge.id}#antwoorden`}>
              {challenge.replyCount}
            </Link>
          ) : (
            <span>{challenge.replyCount}</span>
          )}{' '}
          antwoorden
        </p>

        <Button link={'/challenge/' + challenge.id}>Bekijk challenge</Button>
      </div>
    );
  }
}

Card.propTypes = {
  challenge: type.object
};

import React, { Component } from 'react';
import type from 'prop-types';
import './H2.css';

export default class H2 extends Component {
  render() {
    return (
      <span className="H2_content">{this.props.children}</span>
    );
  }
}

H2.propTypes = {
  children: type.string
}
import React, { Component } from 'react';
import './CardPlaceholder.css';

import { Placeholder } from '../../General';

export default class Card extends Component {
  render() {
    return (
      <div className="Card_container">
        <p className="Card_head">
          <Placeholder />
        </p>

        <p className="Card_title">
          <Placeholder />
        </p>

        <Placeholder image />

        <p>
          <Placeholder />
        </p>

        <p>
          <Placeholder />
          <br />
        </p>
      </div>
    );
  }
}

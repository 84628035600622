const env = 'prod';

const ga = require('./GA');
// const ga = { trackingId: 'UA-123345' }; //testing
const server = env === 'dev' ? 'http://localhost:3001' : 'https://mijnidee.be';
//const server = 'http://localhost:3001';
const graphQL = server + '/graphql';

export default {
  server,
  graphQL,
  ga
};

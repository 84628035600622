import React, { Component } from 'react';

import { Footer, Button, Link, Input, ContentHeader } from '../../General';
import { ChallengeReply } from '../../Challenge';
import { graphQLFetch, sendAnalytics } from '../../../helpers';

import './ChallengeScreen.css';
import logo from '../../../images/logo.svg';
import queries from './queries';

import img1 from '../../../images/challenges/vdcalseyde_cropped.png';
import img2 from '../../../images/challenges/vdcalseyde2_cropped.png';

class VDCalseyde extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newReplyReplyId: null,
      descriptionShort: '',
      showMore: false,
      challengeReplies: null
    };

    this.upvoteReply = this.upvoteReply.bind(this);
    this.addReplyReply = this.addReplyReply.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.getChallengeReplies = this.getChallengeReplies.bind(this);
  }

  componentDidMount() {
    this.getChallengeReplies(6);

    sendAnalytics(this.props);
  }

  componentDidUpdate(prevProps) {
    // This element only exists after fetching data, that's why we check in componentDidUpdate
    if (window.location.hash === '#antwoorden') {
      const antwoorden = document.getElementById('antwoorden');
      if (antwoorden) {
        antwoorden.scrollIntoView({
          block: 'start',
          inline: 'nearest',
          behavior: 'instant'
        });
      }
    }

    if (this.props.location !== prevProps.location) {
      sendAnalytics(this.props);
    }
  }

  scrollToReplies() {
    const antwoorden = document.getElementById('antwoorden');
    if (antwoorden) {
      antwoorden.scrollIntoView({
        block: 'start',
        inline: 'nearest',
        behavior: 'smooth'
      });
    }
  }

  scrollToTop(e) {
    // window.scroll({ top: 0, behavior: 'smooth' });

    const top = document.getElementById('root');
    if (top) {
      top.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  upvoteReply(id) {
    let votedFor = JSON.parse(localStorage.getItem('votedFor'));

    if (!votedFor) votedFor = [];
    if (votedFor.indexOf(id) !== -1) {
      return;
    }

    votedFor.push(id);
    localStorage.setItem('votedFor', JSON.stringify(votedFor));

    return graphQLFetch(queries.upvoteReplyQuery(id))
      .then(() => {
        this.getChallengeReplies(6);
      })
      .catch(err => {
        console.log(err);
      });
  }

  addReplyReply(challengeId, text, token) {
    if (token) {
      return graphQLFetch(
        queries.addReplyReplyQueryByCompany(challengeId, text, token)
      )
        .then(replyReplyJson => {
          this.getChallengeReplies(6, replyReplyJson.data.addReplyReply.id);
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      return graphQLFetch(queries.addReplyReplyQuery(challengeId, text))
        .then(replyReplyJson => {
          this.getChallengeReplies(6, replyReplyJson.data.addReplyReply.id);
        })
        .catch(err => {
          console.log(err);
        });
    }
  }

  getChallengeReplies(challengeId, newReplyReplyId = null) {
    graphQLFetch(queries.getChallengeRepliesQuery(challengeId)).then(json => {
      if (!json.data.challengeReplies) {
        throw Error('Geen antwoorden gevonden');
      }

      this.setState({
        challengeReplies: json.data.challengeReplies,
        newReplyReplyId
      });
    });
  }

  handleDescriptionChange(e) {
    this.setState({ descriptionShort: e.target.value });
  }

  render() {
    const challengeReplies = this.state.challengeReplies;

    if (this.state.error) return <p>Er is iets misgegaan :(</p>;

    return (
      <div>
        <div className="ChallengeScreen_ container">
          <Link to="/">
            <img
              src={logo}
              alt="Logo"
              className="ChallengeScreen_header_logo"
            />
          </Link>

          <div className="ChallengeScreen_header">
            <p className="ChallengeScreen_header_title">
              Hoe kunnen wij jou de ideale 2.0 shopervaring bezorgen?
            </p>

            <Input
              multiline
              placeholder="Mijn idee of mening hierover is..."
              onChange={this.handleDescriptionChange}
            />

            <Button
              iconRight="ion-ios-arrow-forward"
              link={{
                pathname: `/challenge/6/antwoord`,
                state: {
                  descriptionShort: this.state.descriptionShort
                }
              }}
            >
              Verstuur je idee
            </Button>

            {challengeReplies && challengeReplies.length === 0 ? null : (
              <a onClick={this.scrollToReplies}>
                Ontdek anderen hun ideeën
              </a>
            )}
          </div>

          <div className="ChallengeScreen_two-cols">
            <div className="ChallengeScreen_col-left">
              <div className="ChallengeScreen_col-left_rotated">
                <img
                  src={img1}
                  alt="Louis en Arnaud - Van de Calseyde."
                  className={'ChallengeScreen_col-left_img-rotated'}
                />
              </div>
            </div>
            <div>
              <ContentHeader>
                Waarom willen Louis en Arnaud dit weten?
              </ContentHeader>
              <p>
                Wij willen door digitalisering jouw shopervaring optimaliseren.{' '}
                <br /> <br />
                Onze droom is dat als jij verkoopt die goederen automatisch
                opnieuw besteld kunnen worden. <br /> Dat wij met jou een{' '}
                <strong>optimale inventaris met de juiste rotatie</strong>{' '}
                (nooit teveel of te weinig op stock) kunnen opbouwen en dat wij
                jou op op basis van data kunnen adviseren over wat en wanneer te
                kopen. <br /> <br />
                Wat heb jij daarvoor nodig? Wat verwacht je van ons?
              </p>
              <p className="ChallengeScreen_idea-link">
                <a onClick={this.scrollToTop}>
                  Ik heb een idee!
                </a>
              </p>
            </div>
          </div>

          {challengeReplies && challengeReplies.length > 0 ? (
            <ContentHeader id="antwoorden">
              Anderen denken er zo over
            </ContentHeader>
          ) : null}
          <div className="ChallengeScreen_replies">
            {challengeReplies &&
              challengeReplies.map((reply, i) => {
                return this.state.showMore || i < 3 ? (
                  <ChallengeReply
                    key={reply.id}
                    reply={reply}
                    active={true}
                    votedFor={JSON.parse(localStorage.getItem('votedFor'))}
                    onUpvote={() => {
                      this.upvoteReply(reply.id);
                    }}
                    onReply={text => {
                      this.addReplyReply(
                        reply.id,
                        text,
                        localStorage.getItem('token')
                      );
                    }}
                    newReplyReplyId={this.state.newReplyReplyId}
                  />
                ) : null;
              })}
          </div>

          {!this.state.showMore &&
          challengeReplies &&
          challengeReplies.length > 0 &&
          challengeReplies.length > 3 ? (
            <Button
              onClick={() => this.setState({ showMore: true })}
              iconRight="ion-ios-arrow-down"
            >
              Toon alle ideeën
            </Button>
          ) : null}

          <ContentHeader>Over Van de Calseyde</ContentHeader>
          <div className="ChallengeScreen_two-cols">
            <div>
              <div className="ChallengeScreen_light-text">
                <p>
                  Wij zijn groothandelaars in hydraulische slangen en
                  koppelingen. Deze artikelen vind je terug op kranen,
                  vrachtwagen, heftruks, tractors, machines,… <br />
                  <br />
                  Van zodra er iets zwaars in beweging wordt gebracht is de kans
                  groot dat daar hydrauliek aan te pas komt en wij jou kunnen
                  helpen! <br />
                  Onze slogan is <strong>We’ve got it!</strong> <br />
                  <br />
                  Maak jou geen zorgen over het product dat je bij ons aankoopt.
                  <br />
                  Het is kwaliteit, we adviseren juist en het wordt op tijd
                  geleverd. <br />
                  Wij hebben bovendien een van de grootste stocks van België en
                  kunnen jou hierdoor heel snel van dienst zijn met de producten
                  die jij ‘nu’ nodig hebt.
                </p>
                <p>
                  <i className="icon ion-ios-globe" /> &nbsp;Regio: Gent
                </p>
                <p>
                  <i className="icon ion-ios-link" /> &nbsp;
                  <a
                    href="https://www.vandecalseyde.be/nl"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    vandecalseyde.be
                  </a>
                </p>
              </div>
            </div>
            <div className="ChallengeScreen_col-right">
              <img
                src={img2}
                alt="Hydraulica."
                className="ChallengeScreen_col-right_img"
              />
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

export default VDCalseyde;
